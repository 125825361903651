/* .item */
.MenuItem {
  width: 18vw;
  max-width: 192px;
  margin: 5px;
  visibility: hidden;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  z-index: 1;
}

.MenuItem:hover {
  cursor: pointer;
}

.MenuItem a {
  width: 100%;
}

.MenuItem div {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.MenuItem .menu-image {
  position: relative;
}

.MenuItem .menu-image img {
  width: 100%;
  height: 18vw;
  max-height: 192px;
  z-index: 1;
}

.menu-desc.unavailable::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255,255,255,0.35);
}

.menu-image.unavailable::before {
  content: "Unavailable";
  font-size: 20px;
  font-family: 'Gill Sans Bold';
  color: #707070;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255,255,255,0.8);
}

.menu-desc {
  background-color: #FFFFFF;
  position: relative;
  z-index: 1000;
  color: var(--color-text-primary);
  text-align: left;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.35);
  padding: 10px 12px 30px 12px;
  width: 100%;
  flex-grow: 1;
}
.menu-desc h5 {
  font-size: 1.0em;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  max-width: 98%;
  overflow: hidden;
  line-height: 1.2em;
}
.menu-desc .PriceWrapper {
  position: absolute;
  bottom: 0px;
  left: 12px;
}
.menu-desc .Price {
  color: var(--color-text-primary);
}
.menu-desc .From {
  font-size: 0.8em;
  font-style: italic;
  color: #95989A;
  padding-right: 5px;
}
.menu-desc .EnergyWrapper {
  position: absolute;
  bottom: 0px;
  right: 12px;
}
.menu-desc .Energy {
  color: #95989A;
  font-size: 0.8em;
}
.menu-desc ul li {
  display: inline-flex;
}

.MenuItem .PromoFlag {
  font-size: 0.75em;
  color: var(--color-text-contrast);
  padding: 3px 7px;
  position: absolute;
  top: 0;
  left: 0;
}

.MenuItem .UpsellStatus {
  display: none;
}

/* Mobile Landscape */
@media (max-width: 767px) and (min-width: 480px) {
  /* .item */
  .MenuItem {
    width: 29vw;
    max-width: none;
    margin: 4px;
  }

  .MenuItem .menu-image img {
    height: 29vw;
    max-height: none;
  }
}

/* Mobile Portrait */
@media (max-width: 479px) {
  .MenuItem {
    width: 44vw;
    max-width: none;
    margin: 3px;
  }

  .MenuItem .menu-image img {
    height: 44vw;
    max-height: none;
  }
}
