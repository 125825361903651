/* .section-separator */
.SectionSeparator {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 0;
}

.SectionSeparator ul {
  display: flex;
  justify-content: center
}

.SectionSeparator ul li {
  flex: 1;
}

.SectionSeparator ul .name {
  flex: 1 0 auto;
  max-width: 100%;
}

.SectionSeparator .line {
  border-top: 1px solid rgba(60,97,66,0.4);
  margin-top: 10px;
  height: 10px;
  width: 100vw;
}

.SectionSeparator .name {
  padding: 0 10px;
}
