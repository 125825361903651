.NutritionalSection {
  clear: both;
  padding: 6px 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: calc(100vh - 45px);
  overflow-y: auto;
}
.NutritionalSection p {
  margin: 22px 0 16px;
  color: #454545;
  font-size: 0.85em;
}

.NutrientsList {
  column-count: 2;
  flex-wrap: wrap;
}

.NutrientsList ul {
  display: flex;
}

.NutrientsList ul li {
  display: flex;
  color: var(--color-text-primary);
  font-size: 0.95em;
  padding: 4px 0;
  flex: 1;
}

.NutritionalSection .NutritionalDisclaimer {
  margin: 16px 0;
  font-size: 0.85em;
  color: var(--color-text-primary);
}
.NutritionalDisclaimer span {
  font-style: italic;
  color: #454545;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .NutritionalSection {
    position: initial;
    text-align: left;
    padding: 0 10px;
    overflow: visible;
  }
  .NutritionalSection .content-top {
    margin-left: 0;
  }
  .NutrientsList {
    column-count: unset;
  }
  .NutrientsList ul li {
    padding: 0;
  }
}
