.AddItemButton {
  margin-top: 14px;
  display: inline-block;
  border: 2px solid var(--color-secondary) !important;
  border-radius: 5px;
  width: 53%;
  color: var(--color-primary);
  height: 50px;
}

@media (max-width: 992px) {
  .AddItemButton {
    width: 100%;
  }
}
