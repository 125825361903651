.CartPaymentInfo {
  grid-area: cart-payment-info;
  margin: 0 12px 0 24px;
}

.CartPaymentInfo .CartPaymentDetails {
  display: flex;
  flex-direction: column;
}

/*--- Desktop and Tablet Landscape ---*/
@media (max-width: 992px) and (min-width: 768px) {
  .CartPaymentInfo {
    margin: 0 8px 0 16px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .CartPaymentInfo {
    background-color: var(--color-modal-primary);
    box-shadow: 0px -2px 3px 1px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 5px 5px 0 0;
    margin: 0;
  }

  .CartPaymentInfo .CartPaymentDetails {
    padding: 8px 24px 0;
  }
}
