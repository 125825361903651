.Personalize .MenuDetailImage {
  background-color: #FFFFFF;
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  overflow-x: hidden;
}

.Personalize {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}

.PersonalizeForm .Select {
  width: 100%;
  color: #7D7769;
}

.Personalize .MenuContentFrame {
  padding-bottom: 8px;
  margin-bottom: 54px;
}

.Personalize .MenuContentFrame .IngredientDescription {
  text-align: justify;
}

.PersonalizeInfo .IngredientsText {
  margin: 8px 0 0;
}

.Personalize .CustomizeLeft > div {
  flex: 1;
}

/*--- Desktop Only ---*/
@media (min-width: 993px) {
  .Personalize .MenuContentFrame {
    margin-bottom: 0px;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .Personalize {
    flex-direction: column;
  }

  .Personalize .MenuDetailImage {
    flex: 1 0 auto;
  }

  .PersonalizeInfo {
    max-width: 600px;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .PersonalizeInfo {
    max-width: 600px;
  }
}

/*--- Tablet and Mobile Only ---*/
@media (max-width: 992px) {
  .Personalize {
    flex-direction: column;
  }
  
  .Personalize .MenuContentFrame {
    overflow: initial;
  }

  .Personalize .CustomizeLeft {
    min-height: 36vh;
    height: 36vh;
  }

  .Personalize .CustomizeRight {
    overflow: initial;
    flex-direction: initial;
    flex: 1 0 auto;
  }
}

/* Mobile Portrait and Landscape */
@media (max-width: 992px) {
  .Personalize .CustomizeLeft .NotPremadeItem {
    flex-direction: column-reverse;
  }
  .Personalize .NutritionalBlock {
    position: fixed;
  }
}
