.TNCSection {
  width: 100%;
  box-shadow: none !important;
  border-top: 2px solid var(--color-background);
  padding: 15px;
  text-align: left;
}

.TNCSection .Title {
  padding-bottom: 8px;
}

.TNCSection .TNCDetails {
  max-height: 250px;
  overflow-y: auto;
  background-color: var(--color-content);
}
