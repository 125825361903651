.PickupTime {
  width: 100% !important;
}
.PickupTime .SelectWrapper #pickup-type {
  width: 100% !important;
  background-color: var(--color-secondary);
  color: var(--color-text-contrast);
  font-size: 14px;
  padding-left: 40px;
}

.PickupTime .SelectWrapper.whiteCaret::after {
  color: var(--color-text-contrast);
}

.PickupTime .scheduled {
  display: flex;
  justify-content: space-between;
}

.PickupTime .SelectWrapper.fullWidth {
  flex: 1;
  margin-right: 10px;
}
.PickupTime #pickup-date.Select,
.PickupTime .TimePicker .Select {
  padding: 0 20px;
  height: auto;
}

.PickupTime .Select {
  margin-bottom: 10px;
}

.PickupTime .ant-time-picker {
  width: 100%;
}

.ant-time-picker-panel-select-option-disabled {
  display: none;
}

.PickupTime .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-schedule-order.svg);
  position: absolute;
  color:var(--color-text-contrast);
  top: 15px;
  left: 13px;
}

.PickupTime.isInstant .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-instant-order.svg);
}

.PickupTime .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-schedule-order.svg);
  position: absolute;
  color:var(--color-text-contrast);
  top: 15px;
  left: 13px;
}

.PickupTime.isInstant .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-instant-order.svg);
}

.PickupTime .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-schedule-order.svg);
  position: absolute;
  color:var(--color-text-contrast);
  top: 15px;
  left: 13px;
}

.PickupTime.isInstant .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-instant-order.svg);
}
