.Input {
  padding: 12px !important;
  background-color: transparent !important;
  border: 1px solid var(--color-secondary) !important;
  border-radius: 5px;
  box-shadow: none;
  color: var(--color-text-primary);
  text-align: left;
}

.Input::placeholder {
  color: var(--color-secondary);
}

.Input.hasError {
  border: 1px solid #D85A5B !important;
}

.Input.hasError,
.Input.hasError::placeholder {
  color: #D85A5B;
}

.Input:focus {
  outline: none;
}

.Input span.caret {
  color: var(--color-secondary);
  position: absolute;
  right: 20px;
  top: 20px;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
