.PricingError h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.PricingError .Buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.PricingError .Buttons .Button {
  max-width: 50%;
}

.PricingError .Buttons .Button {
  margin: 0 10px;
  padding: 12px 0;
  flex: 1;
}

.PricingError .modal-body {
  padding-bottom: 35px;
}
