.PastScan {
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-panel-primary);
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.PastScan .ScanDate {
  margin-right: 8px;
}

.PastScan .PastScanHeader {
  display: flex;
  justify-content: space-between;
}

.PastScan .HeaderLeft {
  display: flex;
  align-items: center;
  justify-items: center;
}
