a.Button, a.Button:hover, a.Button:focus {
  color: inherit;
}

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Button > span {
  font-family: 'Gill Sans Bold';
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.Button.disabled {
  opacity: 0.8;
}

.Button.loading {
  position: relative;
}

.Button.loading .fa-spin {
  position: absolute;
  top: 20px;
  left: 20px;
}

.Button.btn-teal {
  color: var(--color-text-contrast);
  background-color: var(--color-secondary);
  border: solid 1px #90BF99;
}
.Button.btn-teal:hover, .Button.btn-teal:focus, .Button.btn-teal:active {
  color: var(--color-text-contrast);
  background-color: var(--color-secondary) !important;
  border: solid 1px #90BF99;
}
.Button.btn-primary {
  color: var(--color-text-contrast);
  background-color: var(--color-primary);
  padding: 12px 60px;
  font-size: 1em;
  border-radius: 10px;
  outline: 0!important;
}
.Button.btn-primary:hover, .Button.btn-primary:focus, .Button.btn-primary:active {
  color: var(--color-text-contrast);
  background-color: var(--color-dark) !important;
  outline: 0 !important;
  border: 1px solid var(--color-dark);
}
.Button.disabled.btn-primary:hover {
  background-color: var(--color-primary);
}
.Button.btn-secondary {
  border: 1px solid var(--color-primary);
  padding: 16px 60px;
  display: block;
  font-size: 1em;
  color: var(--color-text-primary);
}
.Button.btn-secondary:hover, .Button.btn-secondary:focus {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
}
.Button.btn-round {
  background-color: #8CA1A5;
  border-radius: 15px;
  color: var(--color-text-contrast);
  padding: 8px 12px;
  font-size: 0.9em;
}
.Button.btn-round:hover, .Button.btn-round:active, .Button.btn-round:focus {
  color: var(--color-text-contrast);
  background-color: #69868B !important;
}
.Button a {
  color: inherit;
}
.Button a:focus {
  color: inherit;
}
.Button a:hover {
  color: inherit;
}

.Button.btn-grey {
  background-color: #8CA1A5;
  padding: 12px 60px;
  font-size: 1em;
  border: 1px solid #8CA1A5;
  border-radius: 10px;
  outline: 0!important;
  color: var(--color-text-contrast);
}
.Button.btn-grey:hover, .Button.btn-grey:focus, .Button.btn-grey:active {
  background-color: #81989D !important;
  outline: 0 !important;
  border: 1px solid #81989D;
}
.Button.btn-grey a {
  color: var(--color-text-contrast) !important;
}

.Button.tabsButton {
  background-color: #969696;
  color: var(--color-content);
}

.Button.tabsButton:hover, .Button.tabsButton:active {
  background-color: #848181;
}

.Button.Split > span {
  width: 100%;
  display: flex;
  max-width: 300px;
  margin: 0 auto;
}

.Button .Text {
  flex: 1;
  padding-left: 19px;
}

.Button .Next {
  margin-right: 16px;
  height: 19px;
  margin-top: 2px;
}
