.Favorites {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.Favorites .ButtonsRow {
  display: flex;
  width: 75%;
}

.Favorites .Button {
  height: 50px;
}

.Favorites .ButtonsRow .btn-primary {
  flex: 3;
}

.Favorites .ButtonsRow .Button.blue-link {
  flex: 2;
}

.Favorites .blue-link {
  text-decoration: none;
}

.FavoriteMenuListWrapper {
  margin-bottom: 8px;
  overflow-y: auto;
}

.FavoriteMenuItemsList {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  overflow-y: auto;
}

.FavoriteMenuItemsList > li {
  flex: 0 0 75%;
}

.FavoriteMenuItemsList .FavoriteMenuItem {
  height: calc(100% - 14px);
}

.OrderHistoryRow {
  display: flex;
  flex-wrap: wrap;
}

.EmptyFavorites {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 80px 20px;
  max-width: 65%;
}

.EmptyFavorites .blue-link {
  margin-left: 5px;
  white-space: nowrap;
}

.EmptyFavorites .blue-link span {
  font-size: 1.1em;
}

.EmptyFavorites .Button {
  width: 100%;
}

.EmptyFavorites p {
  margin: auto;
}

.EmptyFavorites svg {
  margin-bottom: 16px;
}

.Favorites .or {
  font-style: italic;
  margin: 0px auto 16px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .FavoriteMenuItemsList > li {
    flex: 0 0 100%;
  }

  .Favorites .ButtonsRow {
    flex-direction: column;
    width: 100%;
  }

  .Favorites .ButtonsRow .Button {
    margin: 0px;
  }

  .Favorites .ButtonsRow .Button {
    flex: 0;
  }

  .FavoriteMenuItemsList .FavoriteMenuItem {
    width: 100%;
  }

  .EmptyFavorites {
    max-width: unset;
  }

  .EmptyFavorites .Button {
    width: unset;
  }
}
