.SelectedRewardWrapper {
  margin: 24px 49px 0px;
}

.SelectedReward {
  display: flex;
}

.SelectedReward .Title {
  flex: 1;
  margin-right: 8px;
}
