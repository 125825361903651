.CartItem {
  display: flex;
  flex-wrap: wrap;
  min-height: 70px;
  text-align: left;
  position: relative;
  margin-bottom: 8px;
  background-color: var(--color-panel-secondary);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.CartItem .CartItemBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.CartItem .CartItemBody .CartItemDetails {
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
}

.CartItem .CartItemBody .Customer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}

.CartItem .CartItemBody .Customer::before {
  content: "Label:";
  font-size: 12px;
  padding-right: 4px;
}

.CartItem .CartItemBody .CartItemPrice {
  flex: 1;
  text-align: right;
  margin-left: 16px;
}

.CartItem .CartItemBody .CartItemPrice .OrderItemPrice h6 {
  line-height: 0;
}

.CartItem .CartItemBody .CartItemDetails p {
  margin-bottom: 0;
  white-space: pre;
}

.CartItem li {
  overflow: hidden;
  transition: .5s ease-out;
}

.MenuItemCard:focus {
  outline: 0;
}

.MenuItemCard:focus > li {
  transform: translateX(-140px);
}

.CartItem .ingredientChanges {
  white-space: pre-wrap;
}

.MenuItemCard.premade:focus li {
  transform: translateX(-70px);
}

.MenuItemCard .priceLabel {
  font-size: 0.85em;
  color: #7D7769;
  padding-right: 4px;
}

.CartItem:focus .CartItemEdit, 
.CartItem:focus .CartItemRemove, 
.CartItem.premade:focus .CartItemRemove {
  transform: translateX(0);
}

.CartItem .CartItemEdit, 
.CartItem .CartItemRemove {
  display: block;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transform: translateX(140px);
}

.CartItem.premade .CartItemRemove {
  transform: translateX(70px);
}

.CartItem .CartItemEdit button, 
.CartItem .CartItemRemove button {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
}

.CartItem .CartItemEdit button img, 
.CartItem .CartItemRemove button img {
  width: 69px;
}

.CartItem .CartItemEdit {
  right: 70px;
  border-right: 1px solid #F2EDE9;
  background-color: #8FB291;
}

.CartItem .CartItemRemove {
  background-color: #E39E9F;
}

.CartItem img {
  width: 100%;
  height: 70px;
}

.CartItem .CartItemImage {
  width: 70px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #FFFFFF;
}

.CartItem .EditIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.75em;
  color: var(--color-secondary);
}
