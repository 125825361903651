.Autocomplete {
  position: relative;
}

.AutocompleteSuggestions {
  position: absolute;
  top: 100%;
  background: white;
  width: 100%;
  border-top: solid 1px lightgray;
  border-right: solid 1px lightgray;
  border-left: solid 1px lightgray;
}

.AutocompleteSuggestion {
  border-bottom: solid 1px lightgray;
}

.AutocompleteSuggestionButton {
  text-align: left;
  width: 100%;
  padding: 10px;
}

.AutocompleteSuggestionButton:hover {
  background-color: #EEEEEE;
}

.Autocomplete .InputAlertText {
  margin-top: 4px;
}
