.PickFavorite .modal-dialog {
  width: 420px;
}
.PickFavorite.modal .modal-dialog .modal-body {
  padding: 25px;
}
.PickFavorite .modal-content p {
  padding-bottom: 6px;
}
.PickFavorite .modal-body>ul {
  margin-bottom: 15px;
}
.PickFavorite .modal-input {
  margin-bottom: 20px;
  width: 95%;
  margin-top: 7px;
}
.PickFavorite .modal-body>.Button {
  margin-top: 10px;
  width: 80%;
}
.PickFavorite .OrderItem>ul>li:first-child .order-img {
  min-height: auto;
}
.PickFavorite .OrderItem>ul>li:nth-child(2) p {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .PickFavorite .modal-dialog {
    width: 90%;
  }
  .PickFavorite .modal-content {
    max-width: 360px !important;
    margin: 30px auto 20px;
  }
}
