.MuiSlider-root {
  height: 52px !important;
  width: 260px !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.MuiSlider-rail,
.MuiSlider-track {
  height: 52px !important;
  border-radius: 10px !important;
}

.MuiSlider-rail {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.MuiSlider-thumb {
  content: url(../../images/icon-arrow.svg);
  padding: 10px;
  width: 52px !important;
  height: 52px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  transform: translate(-10%, -50%) !important;
}

.MuiSlider-root .fa {
  color: white;
}

.RailText {
  color: white;
}
