.CategoryPaginationBar {
  background-color: #FFFFFF;
  text-align: center;
  margin: 14px 0;
  padding: 0%;
}

.CategoryPaginationBar > li {
  display: inline-block;
}

.CategoryPaginationBar > li:first-child {
  margin-right: 2%;
}

.CategoryPaginationBar > li:first-child > ul > li {
  display: inline-block;
  font-size: 1.05em;
  margin-right: 20px;
  opacity: 0.6;
}

.CategoryPaginationBar > li:first-child > ul > li:last-child {
  margin-right: 0;
}

.CategoryPaginationBar > li:first-child li.selected {
  opacity: 1 !important;
  padding-bottom: 4px;
  background-color: initial !important;
  border: initial !important;
  border-bottom: 2px solid var(--color-primary) !important;
}

.CategoryPaginationBar > li:nth-child(2) input {
  width: 100%;
}

.CategoryPaginationBar > li:nth-child(3) {
  padding-left: 2%;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .CategoryPaginationBar {
    background-color: var(--color-content);
    height: 52px;
    margin: 0;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }

  .CategoryPaginationBar > li:first-child {
    width: 100%;
  }

  .CategoryPaginationBar > li:first-child > ul {
    width: 100%;
    display: flex;
  }

  .CategoryPaginationBar > li:first-child > ul > li {
    opacity: 1;
    height: 51px;
    line-height: 51px;
    flex: 1;
  }

  .CategoryPaginationBar > li:first-child li > button {
    font-size: 0.95em;
    line-height: 20px;
  }

  .CategoryPaginationBar > li:first-child li.selected {
    color: var(--color-text-primary) !important;
    border-bottom: 3px solid var(--color-primary) !important;
    font-weight: bold;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .CategoryPaginationBar > li:first-child > ul > li {
    margin-right: 10px;
  }
}
