.MenuDetailsView .PricingVariationsNotifications {
  padding: 35px 20px 10px;
}

.MenuDetailsAdd .PricingVariationsNotifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 0;
}

.PricingVariationsNotifications:empty {
  display: none;
}

.PricingVariationsNotification {
  font-size: 0.85em;
  display: flex;
  margin: 10px 0 0;
  padding: 12px 10px;
  max-width: 500px;
}

.PricingVariationsNotification .SaladIcon {
  flex: 0;
  height: 20px;
}

.PricingVariationsNotification .Text {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 5px;
}

.PricingVariationsNotification .Text .Content {
  width: 100%;
  text-align: center;
}

.PricingVariationsNotification .Text .PreviousPrice:before {
  content: '\00a0\00a0';
}

.PricingVariationsNotification .Text .PreviousPrice:after {
  content: '\00a0\00a0';
}

.PricingVariationsNotification .Text .PreviousPrice {
  text-decoration: line-through;
  margin-left: 10px;
  margin-right: 5px;
  color: gray;
}

.PricingVariationsNotification .Add {
  flex: 0;
}

@media(max-width: 992px) {
  .PricingVariationsNotification {
    width: 100%;
  }
}
