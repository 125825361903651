.DietInformation {
  margin-left: 10px;
  top: 5px;
  position: relative;
}

.DietInformation ul li {
  display: inline-block;
  width: 22px;
  margin-right: 4px;
}

.DietInformation ul li:last-of-type {
  margin-right: 0;
}

.DietInformation ul li img {
  max-width: 100%;
  height: auto;
}
