.LoyaltyCard {
  margin: 8px;
  display: flex;
  flex-direction: row;
  width: 640px;
  height: 144px;
  border-radius: 10px;
  background-color: var(--color-panel-primary);
  cursor: pointer;
}

.LoyaltyCard .LoyaltyCardContentContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.LoyaltyCard .LoyaltyCardImageContainer {
  flex: 1;
  display: flex;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.LoyaltyCard .LoyaltyCardImageContainer img {
  max-width: 100%;
  object-fit: cover;
}

.LoyaltyCard .TitleContainer {
  flex: 1;
  width: 100%;
  align-self: flex-start;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.LoyaltyCard .Title {
  padding: 12px 15px;
  color: var(--color-text-contrast);
  background-color: var(--color-primary);
}

.LoyaltyCard .NameContainer {
  padding: 10px 15px;
}

.LoyaltyCard .CardBottom {
  padding: 0px 16px 10px;
}

.LoyaltyCard .CardBottom .Validity {
  margin-top: 8px;
}

.LoyaltyCard .ProgressBarBackground {
  height: 16px;
  border-radius: 10px;
}

.LoyaltyCard .ProgressBar {
  height: 16px;
  background-color: #f2c241;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .LoyaltyCard {
    flex-direction: column;
    width: unset;
    height: unset;
  }

  .LoyaltyCard .TitleContainer {
    border-radius: 0;
  }

  .LoyaltyCard .LoyaltyCardImageContainer {
    width: 100%;
    height: 96px;
    display: flex;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }

  .LoyaltyCard .LoyaltyCardImageContainer img {
    object-fit: cover;
    flex: 1;
  }
}
