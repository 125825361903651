.Reorder .nav-tabs {
  background-color: var(--color-content);
  display: flex;
  border: none;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.Reorder .nav-tabs > li.active > a {
  border-bottom: 5px solid var(--color-primary) !important;
  padding: 15px 15px 11px 15px;
}

.Reorder .nav-tabs > li > a {
  color: var(--color-text-primary) !important;
  width: 100%;
  padding: 15px;
  text-align: center;
}

.Reorder .tab-content {
  padding: 15px;
}
