.ContentSeparator ul li {
  display: inline-block;
}
.ContentSeparator {
  position: relative;
  text-align: left;
  margin-bottom: -20px;
  width: 100%;
  padding-left: 32px;
}
.ContentSeparator p {
  background-color: #E8E5E0;
  position: relative;
  top: -24px;
  font-size: 0.95em;
  color: #7D7769;
}
.ContentSeparator .line {
  border-top: 1px solid #FFFFFF;
  margin-top: 24px;
  height: 10px;
  width: 100%;
}
@media(max-width:766px){
  .ContentSeparator {
    padding-left: 24px;
  }
}
