.DietModal h5 {
  font-weight: 700;
}
.DietModal > .modal-dialog {
  width: 588px;
}

.DietModal .diet-support-list {
  width: 100%;
  margin: 22px 0 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.DietModal .btn-primary {
  width: 100%;
}

.DietModal .NutritionalChatButton {
  width: 100%;
  margin-top: 8px;
  padding: 12px 60px;
  color: var(--color-text-hyperlink);
  border: solid 1px var(--color-text-hyperlink);
  border-color: var(--color-text-hyperlink);
  border-radius: 10px;
  background-color: transparent;
}

.DietModal .NutritionalChatButton .fa {
  margin-right: 4px;
  font-size: 1.2em;
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px){
  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }

  .DietModal .diet-support-list {
    margin: 10px 0;
  }

  .DietModal .btn-primary {
    margin-bottom: 12px;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModal .modal-body {
    padding: 24px 16px 8px;
  }

  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }

  .DietModal .diet-support-list {
    margin: 16px 0;
  }

  .DietModal .NutritionalChatButton {
    margin-bottom: 16px;
    padding: 12px 30px;
  }
}
