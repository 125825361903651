/* img-store */
.PickupTimeSelector {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
}

.PickupTimeSelector .ImageStore {
  display: flex;
  flex-direction: column;
  flex: 2;
  border-right: 1px solid #FFFFFF;
  overflow: auto;
}

.PickupTimeSelector .ImageStore>img {
  flex: 1;
  max-height: 100%;
  object-fit: cover;
}

/* .content-container */
.PickupTimeSelector .ContentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 35px;
}

.PickupTimeSelector .ContentContainer .title {
  margin-bottom: 8px;
}

.PickupTimeSelector .ContentContainer .ActionButton>.Button {
  position: initial;
}

.PickupTimeSelector .ContentContainer .GglocationInfo {
  padding: 0 30px;
  width: 100%;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .content-container */
  .PickupTimeSelector {
    flex-direction: column;
  }

  .PickupTimeSelector ul.picker-time li {
    display: inline-flex;
    width: 47.3%;
    height: 52px;
    margin: 8px 1%;
  }

  /* #img-store */
  .PickupTimeSelector .ImageStore {
    border-right: none;
    position: relative;
    max-height: 22vh;
  }

  .PickupTimeSelector .ContentContainer {
    padding-top: 16px;
  }

  .PickupTimeSelector .ContentContainer .GglocationInfo {
    padding: 0 24px;
  }

  .PickupTimeSelector .shop-info p.shop-add {
    margin: 10px auto 12px auto;
    width: 100%;
    position: relative;
    font-size: 0.95em;
  }

  .PickupTimeSelector .Image-original {
    display: none;
  }
}
