.HomeButton {
  display: none;
}

.HomePage .BackButton {
  display: none;
}

.TopRightBackButton {
  display: none;
}

.SignInPage .UserButton {
  display: none;
}

.SignInPage .CartButton {
  display: none;
}

.SignInPage.PhonePage .CartButton {
  display: initial;
}

.AccountPage .UserButton {
  display: none;
}
.AccountPage .BackButton {
  display: none;
}
.AccountPage .HomeButton {
  display: initial;
}


.OrderPage .HomeButton {
  display: initial;
}
.OrderPage .BackButton {
  display: none;
}

.UpsellPage .TopBar .TopBarLeftButton {
  visibility: hidden;
}
.UpsellPage .TopBar .CartButton {
  display: none;
}
.UpsellPage .TopBar .UserButton {
  display: none;
}
.UpsellPage .TopBar .TopRightBackButton {
  display: initial;
}
.UpsellPage .TopBar .TopRightBackButton .fa {
  font-size: 1.4em;
}

.TopBar .LoyaltyTabs {
  display: none;
}

.HomePage .TopBar .LoyaltyTabs,
.LoyaltyPage .TopBar .LoyaltyTabs {
  display: flex;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .loggedIn .UserButton {
    display: none;
  }

  .HomePage:not(.loggedIn) .CartButton {
    display: none;
  }

  .ShoppingPage .UserButton {
    display: none;
  }

  .AccountPage .HomeButton {
    display: none;
  }

  .OrderPage.loggedIn .UserButton {
    display: initial;
  }
  .OrderPage .CartButton {
    display: none;
  }

  .LocationsPage:not(.loggedIn) .CartButton {
    display: none;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .UpsellPage .TopBar .TopBarLeftButton,
  .UpsellPage .TopBar .TopBarRightButton {
    display: none;
  }
}
