/* .menu-img */
.MenuHeader {
  min-height: 300px;
  width: 100%;
}
.MenuHeader span {
  display: flex;
  height: 100%;
}
.MenuHeader img {
  height: 100%;
  min-height: 300px;
  width: 100%;
  object-fit: cover;
}
.MenuHeader h2 {
  position: relative;
  top: -165px;
  margin-bottom: -105px;
  text-shadow: 0px 1px 3px rgba(0,0,0,0.4);
}
.MenuHeader img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 993px) {
  .MenuHeader {
    display: none;
  }
}
