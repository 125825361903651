.LoyaltyTab {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-contrast);
  position: relative;
}

.LoyaltyTabs.tab .LoyaltyTab {
  flex: 1;
  flex-direction: column;
  color: var(--color-text-primary);
  padding-top: 8px;
  font-size: 0.9em;
}

.LoyaltyTab .TabImageWrapper {
  position: relative;
  z-index: 1;
  margin-bottom: 8px;
}

.LoyaltyTab .TabImageWrapper img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.LoyaltyTab.isSelected {
  font-weight: bold;
}

.LoyaltyTabs.tab .LoyaltyTab.isSelected .TabImageWrapper::before {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: var(--color-primary);
  content: '';
  top: -18px;
  left: -24px;
  position: absolute;
  z-index: -1;
}

.LoyaltyTabs.tab .LoyaltyTab.isSelected .TabImageWrapper img {
  position: absolute;
  top: -7px;
  left: -12px;
}

/*--- For desktop ---*/
@media (min-width: 992px) {
  .LoyaltyTabs.bar .LoyaltyTab {
    padding-right: 16px;
  }

  .LoyaltyTabs.bar .LoyaltyTab.order {
    order: -1;
  }

  .LoyaltyTabs.bar .LoyaltyTab .TabImageWrapper {
    padding-right: 4px;
    margin-bottom: 0px;
  }

  .LoyaltyTabs.bar .LoyaltyTab .TabImageWrapper img {
    width: 24px;
    height: 24px;
  }

  .LoyaltyTabs.bar .LoyaltyTab.isSelected {
    color: var(--color-light);
    text-decoration: underline;
  }
}
