/* #map */
.Map {
  display: flex;
  flex: 2;
  border-right: 1px solid #FFFFFF;
}

.MapElement {
  display: flex;
  flex: 1;
  outline: none;
  overflow: hidden;
}

@media (max-width:992px) {
  .Map {
    display: none;
  }
}
