.FavoriteMenuItem {
  display: flex;
  background-color: var(--color-content);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  margin: 0 14px 14px 0;
  color: var(--color-text-primary);
  text-align: left;
}

.FavoriteMenuItem .Image {
  background-color: #FFFFFF;
  width: 90px;
  height: auto;
}

.FavoriteMenuItem .Image img {
  width: 100%;
  height: inherit;
}

.FavoriteMenuItem .Details {
  flex: 1;
  padding: 4px 8px;
}
.FavoriteMenuItem .Details h5 {
  font-size: 1em;
}
.FavoriteMenuItem .EditName {
  font-size: 0.85em;
  margin-left: 5px;
  color: var(--color-secondary);
}
.FavoriteMenuItem .Details p {
  font-size: 0.8em;
  line-height: 1.4em;
}
.FavoriteMenuItem .Details .AddedIngredientsText {
  font-style: italic;
}
.FavoriteMenuItem .Details .RemovedIngredientsText {
  font-style: italic;
}

.FavoriteMenuItem .Toolbar {
  border-left: 1px solid #E2DFD8;
}

.FavoriteMenuItem .Toolbar .Button {
  width: 60px;
  min-width: 60px;
  height: 100%;
  padding: 5px;
  font-size: 0.9em;
}

.FavoriteMenuItem .Toolbar .Button .lnr {
  font-size: 1.25em;
}

.FavoriteMenuItem.NoButtons .EditName, .FavoriteMenuItem.NoButtons .Toolbar {
  display: none;
}
