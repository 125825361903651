.TwoColumns {
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0 auto;
}

.TwoColumns li.column {
  width: 50%;
  text-align: center;
  padding-bottom: 60px;
}

.TwoColumns li.column:first-child {
  border-left: 2px solid  rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 56px);
}


/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .TwoColumns {
    display: block;
  }

  .TwoColumns li.column {
    padding-bottom: 24px;
    width: 100%;
  }

  .TwoColumns li.column:first-child {
    border-left: none;
    min-height: 10px;
  }
}
