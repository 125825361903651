.CarbonOffsetWrapper {
  display: flex;
  flex-direction: column;
}

.CarbonOffsetBody {
  display: flex;
  justify-content: space-between;
}

.CarbonOffsetBody .bi-info-circle{
  cursor: pointer;
}

.CarbonOffsetBody .bi-info-circle{
  cursor: pointer;
}

.CarbonOffsetBody .CarbonOffsetLabel {
  flex: 1 1 auto;
  text-align: left;
}

.CarbonOffsetBody .CarbonOffsetPrice {
  margin-right: 10px;
}

.CarbonOffsetHeader {
  display: flex;
  justify-content: left;
}

.CarbonOffsetHeader svg {
  margin-left: 10px;
}

.CarbonOffsetDescription {
  padding: 8px 24px 24px 24px;
  text-align: justify;
}

.CarbonOffsetTitle {
  padding-top: 16px;
}
.CarbonOffsetModal .modal-control {
  position: absolute;
}

.CarbonOffsetModal .CarbonOffsetInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px 0;
}

.CarbonOffsetButtonPrice {
  margin-left: 8px;
}

.CarbonOffsetModal img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.CarbonOffsetModal button {
  height: 50px;
  width: 80%;
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .CarbonOffsetModal img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
