.Cart .modal-dialog {
  width: 810px;
}

.Cart .modal-content {
  min-height: 320px;
  top: 2vh;
}

.Cart .modal-content {
  background-color: var(--color-modal-cart);
  border-radius: 10px;
}

.Cart .modal-control {
  padding-top: 26px !important;
  z-index: 9999;
}

.Cart .modal-content .CartHeader {
  width: 100%;
  background-color: var(--color-modal-cart);
  color: var(--color-text-contrast);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  top: -60px;
  padding: 16px 0;
  margin-bottom: -60px;
  text-overflow: ellipsis;
}

.Cart .modal-content .CartHeader p {
  font-size: 1.1em;
  margin-bottom: 6px;
}

.Cart .modal-content .CartHeader .hidden-xs span {
  flex-direction: column;
}

.Cart .modal-content .CartHeader .hidden-xs .EditIcon {
  margin-left: 8px;
}

.Cart .CartBody {
  display: flex;
}

.Cart .EmptyCartBody {
  grid-area: 1 / 1 / -1 / -1;
}

.Cart .EmptyCartBody p {
  margin: 12px auto 24px auto;
  line-height: 24px;
}

.Cart .modal-content {
  overflow: visible;
}

.Cart .modal-body {
  padding: 12px 12px 22px 12px;
  overflow-y: auto;
  display: grid;
  grid-template-areas: 
    "cart-body-left cart-checkout" 
    "cart-body-left cart-payment-info";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.Cart .CartModalBody,
.Cart .CartBody {
  display: contents;
}

.Cart .CartBody .CartBodyLeft {
  grid-area: cart-body-left;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Cart .CartBody .CartItemList {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.Cart .CartBody .CartItemList::-webkit-scrollbar {
  width: 8px;
}

.Cart .CartBody .CartItemList::-webkit-scrollbar-track {
  background-color: transparent;
}

.Cart .CartBody .CartItemList::-webkit-scrollbar-thumb {
  background: #B4B4B4;
  border: 1px solid #FFFFFF;
}

.Cart .CartBody .CartItemList::-webkit-scrollbar-thumb:window-inactive {
  background: #CECECE;
}

.Cart .CartBody .CartCheckout {
  position: relative;
  margin: 0 12px 0 24px;
  grid-area: cart-checkout;
  align-self: end;
}

.Cart .CartBody .CartCheckout p.CartLabel {
  color: #7D7769;
  font-size: 0.85em;
  margin-bottom: 6px;
}

.Cart .CartBody .CartCheckout .Select,
.Cart .CartBody .CartCheckout div {
  margin-left: 0;
}

.Cart .CartBody .CartCheckout .CartError {
  display: flex;
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 0;
}

.Cart .CartBody .CartCheckout .CartError .InputAlertText {
  flex: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  width: auto;
}

.Cart .CartBody .CartCheckout .CartError .InputAlertText p {
  margin: 0;
}

.Cart .CartBody .CartCheckout .CartError .CartErrorButton {
  text-align: right;
}

.Cart .deliveryAddress {
  font-size: 14px;
  color: #7D7769;
  text-align: left;
  line-height: 1em;
}

.Cart .deliveryAddress .AddressSticker {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  background-color: var(--color-content);
  border: 1px solid #D0D0D0;
  padding: 13px;
  color: var(--color-text-primary);
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  line-height: 1.4em;
}

.Cart .deliveryAddress .AddressSticker img {
  margin-right: 10px;
}

.Cart .visible-xs .deliveryHeaderMobile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Cart .Checkbox {
  border-top: 1px solid var(--color-secondary);
  padding: 8px 0 0;
  margin-top: 8px;
}

.Cart .Checkbox label::after {
  left: 0;
  bottom: 2px; /* Works for Safari and Chrome */
}

@media (min-width: 768px) {
  .Cart .CartBodyLeft .CartButton .Button {
    width: auto;
  }
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .Cart .modal-dialog {
    width: 88%;
  }

  .Cart .modal-content {
    top: 5vh;
  }

  .Cart .modal-body {
    padding: 16px 8px;
  }

  .Cart .CartBody .CartCheckout {
    position: relative;
    margin: 0 8px 0 16px;
  }

  .Cart .CartBody .CartCheckout .pick-up-time span.instant-order,
  .Cart .CartBody .CartCheckout .pick-up-time span.schedule-order {
    margin-right: 5px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .Cart.modal.fade.in {
    display: block;
    overflow: hidden;
  }

  .Cart,
  .Cart .modal-dialog,
  .Cart .modal-content {
    width: 100% !important;
    height: 100%;
    margin: 0;
    padding: 0 !important;
    border: 0;
    border-radius: 0;
    max-width: none;
  }

  .Cart .modal-content {
    top: 0 !important;
    overflow-y: auto;
  }

  .Cart .modal-body {
    padding: 0;
    height: calc(100% - 48px); /* 52 - 4 */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    background-color: var(--color-content);
  }

  .Cart .modal-body {
    padding: 0;
    height: calc(100% - 48px); /* 52 - 4 */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    background-color: var(--color-content);
  }

  .Cart .modal-body .CartModalBody {
    display: block;
    overflow-y: auto;
  }

  .Cart .modal-control {
    width: 54px;
    height: 52px;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    left: calc(100% - 60px);
  }

  .Cart .modal-control button.btn-close-modal {
    background-image: none;
  }

  .Cart .modal-control button.btn-close-modal::after {
    content: url(./btn-close-modal-white.svg) !important;
    display: block;
    width: 20px;
    height: 18px;
    position: absolute;
    top: 14px;
    right: 8px;
  }

  .Cart .modal-content .CartHeader {
    width: 100%;
    height: 56px;
    background-color: var(--color-primary);
    vertical-align: middle;
    text-align: center;
    position: relative;
    border-radius: 0;
    padding: 18px 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    top: -52px !important;
  }

  .Cart .modal-content .HeaderWrapperMobile span {
    font-size: 1.7em;
    position: relative;
    top: 4px;
    margin-right: 3px;
  }

  .Cart .EmptyCartBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
  }

  .Cart .MinOrderInfo {
    display: none;
  }

  /*--- cart with item --*/
  .Cart .CartBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 24px 16px;
    background-color: var(--color-background);
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .Cart .CartBody .CartItemList {
    width: 100%;
    margin-left: 0px;
    max-height: none;
    overflow: visible;
  }

  .Cart .CartBody .CartCheckout {
    width: 100%;
    padding: 8px 0 0;
    margin: 0 auto;
  }

  .Cart .CartBody .CartCheckout p.CartLabel {
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .Cart .CartBody .CartCheckout .pick-up-time {
    font-size: 0.9em;
  }

  .Cart .CartBody .CartCheckout .pick-up-time span.instant-order,
  .Cart .CartBody .CartCheckout .pick-up-time span.schedule-order {
    margin-right: 5px;
  }

  .Cart .deliveryAddress .AddressSticker {
    border-color: var(--color-secondary);
    line-height: 1.4em;
  }

  .Cart .Checkbox {
    order: -1;
    border-top: none;
    border-bottom: 1px solid var(--color-secondary);
    padding: 4px 0;
    margin-top: 0;
  }

  .Cart .HeaderWrapperMobile {
    display: flex;
    justify-content: center;
  }

  .Cart .HeaderWrapperMobile .EditIcon {
    font-size: unset !important;
    color: var(--color-text-contrast);
    margin-left: 8px;
  }

  .Cart .CartBodyLeft .CartButton .Button {
    display: none;
  }
}
