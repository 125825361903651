.IngredientsPicker {
  margin: 0 auto;
  padding: 16px 32px 0;
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.MenuDetailsAdd.MenuContentFrame .content-main h3 {
  margin-top: 0;
}

.MenuDetailsAdd .MenuDetailsAddBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: auto;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .MenuDetailsAdd.MenuContentFrame {
    padding-top: 0px;
  }
  .MenuDetailsAdd .MenuDetailsAddBody {
    -webkit-overflow-scrolling: touch;
    padding-bottom: 80px;
  }
  .MenuDetailsAdd .MenuDetailsAddBody .IngredientCategories {
    padding-top: 24px;
  }
}
