.LandingPage {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.LandingPage .Content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.LandingPage .Intro {
  display: grid;
  grid-template-areas: 
    'image logo'
    'image content'
    'image content';
  grid-auto-columns: 1fr;
}

.LandingPage .Intro .IntroImg {
  display: flex;
  grid-area: image;
  max-width: 100%;
}

.LandingPage.NoBannerImage .Intro {
  grid-template-areas: 
    'logo'
    'content';
}

.LandingPage.NoBannerImage .Intro .IntroImg {
  display: none;
}

.LandingPage .Intro .IntroImg > img {
  max-width: 100%;
  object-fit: cover;
  flex: 1;
}

.LandingPage .IntroContent {
  grid-area: content;
  display: flex;
  flex-direction: column;
}

.LandingPage .IntroHeader {
  margin: 10px auto;
  font-size: 26px;
  font-weight: bold;
}

.LandingPage .IntroText {
  text-align: center;
  padding: 16px 92px;
  font-size: 14px;
}

.LandingPage .IntroLogo {
  grid-area: logo;
  max-height: 160px;
  margin: 20px auto;
}

.LandingPage .Footer .Top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 95px;
}

.LandingPage .Footer .Top > p{
  text-align: center;
}

.LandingPage .Footer .Top .Logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingPage .Footer .Top .Logos > img {
  margin-right: 10px;
  max-width: 200px;
  max-height: 34px;
}

.LandingPage .Footer .Bottom {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingPage .Footer .Bottom a {
  margin-right: 10px;
}

.LandingPage .Content .IntroContent ol {
  list-style: decimal;
}

.LandingPage .Content .IntroContent ul {
  list-style: circle;
}

/* For older browser (deprecated) */
.LandingPage .Content .IntroContent :matches(a, button, input, li, p, select, span, textarea) {
  font-family: inherit;
}

.LandingPage .Content .IntroContent :is(a, button, input, li, p, select, span, textarea) {
  font-family: inherit;
}

@media (max-width: 767px) {
  .LandingPage .Intro {
    grid-template-areas: 
      'logo'
      'image'
      'content';
  }

  .LandingPage .IntroImg {
    height: 250px;
  }

  .LandingPage .IntroImg > img {
    object-fit: cover;
  }

  .LandingPage .IntroContent {
    margin-top: 16px;
  }
}
