body {
  margin: 0px;
}

.AddressMap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 1px solid #FFFFFF;
  float: left;
}

.AddressMap #mapSearchContainer {
  position: fixed;
  top: 20px;
  right: 40px;
  height: 30px;
  width: 180px;
  z-index: 110;
  font-size: 10pt;
  color: #5D5D5D;
  border: solid 1px #BBBBBB;
  background-color: #F8F8F8;
}

.AddressMap #map {
  width: 100%;
  height: 100%;
  z-index: 100;
}

.AddressMap .pointer {
  position: absolute;
  top: 86px;
  left: 60px;
  z-index: 99999;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  .AddressMap {
    width: 100%;
    height: 200px;
  }
}


/* Tablet Vertical */
@media (max-width: 992px) and (min-height: 992px) {
  .AddressMap {
    height: 400px;
  }
}
