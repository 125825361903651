.LoyaltyRewardsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoyaltyRewardsList .SectionSeparator {
  margin-top: 22px;
  width: 100%;
}

.LoyaltyRewardsList .LoyaltyRewardWrapper {
  width: 100%;
  padding: 0 49px;
}

@media(max-width:768px){
  .LoyaltyRewardsList {
    padding: 0 25px;
  }
}
