.ScheduleTimePicker {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  border-top: solid 1px #FFFFFF;
  text-align: center;
}

.ScheduleTimePicker .Content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 30px;
}

.ScheduleTimePickerOption {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 12px 12px;
  border: solid 1px var(--color-secondary);
  border-radius: 4px;
  text-align: center;
  font-weight: normal;
  height: 46px;
}

.ScheduleTimePickerOption .indicator {
  height: 15px;
  width: 15px;
  border: solid 1px var(--color-secondary);
  border-radius: 50%;
  display: block;
  margin-right: 10px;
  float: left;
  position: relative;
  top: 2.5px;
}

.ScheduleTimePickerOption .content {
  position: absolute;
  left: 0;
  right: 0;
}

.ScheduleTimePickerOption.active {
  background-color: var(--color-secondary);
  color: var(--color-text-contrast);
}

.ScheduleTimePickerOption.active .indicator {
  background-color: #3E6144;
  border-color: #3E6144;
}

.ScheduleTimePickerOption input {
  display: none;
}

.OptionSeparator {
  font-style: italic;
  margin: 14px 0;
  display: block;
}

.ScheduleTimePicker .Header {
  margin: 24px 0;
  font-size: 1.125em;
}

.ScheduleTimePickerDate {
  display: flex;
  width: 100%;
  margin: 16px auto 0;
}

.ScheduleTimePickerDate .SelectWrapper {
  flex: 1;
  height: 50px;
}

.ScheduleTimePickerDate .Select {
  padding-left: 8px;
}

.ScheduleTimePickerDate .dropdown #pickup-date {
  width: 100%;
}

.ScheduleTimePickerDate .TimePicker {
  display: flex;
}

.ScheduleTimePickerDate .TimePicker .SelectWrapper::after {
  display: none;
}

.ScheduleTimePickerDate .TimePicker select {
  background: transparent;
  appearance: none;
  padding: 0 20px;
  line-height: 46px;
  border-color: var(--color-secondary);
  margin-left: 8px;
  border-radius: 4px;
}


/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 992px) {
  .ScheduleTimePicker .Content {
    padding: 0 24px 32px;
  }
  .ScheduleTimePickerOption, .ScheduleTimePickerDate {
    max-width: auto;
  }

  .ScheduleTimePicker .ScheduleTimePickerDate .dropdown-menu {
    top: initial;
    bottom: 100%;
  }
}
