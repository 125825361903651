.SelectPaymentMethod {
  display: flex;
  text-align: left;
  margin: 16px 0 8px;
  align-content: center;
}

.SelectPaymentMethod img {
  width: auto;
  height: 17px;
  margin-right: 10px;
}

.SelectPaymentMethod span {
  vertical-align: middle;
}

.SelectPaymentMethod .PaymentMethodList {
  width: 100%;
  letter-spacing: 0px;
  font-size: 0.85em;
  padding: 0;
  line-height: inherit;
  border: none;
  margin-right: -48px;
}

.SelectPaymentMethod .PaymentMethodList:hover {
  cursor: pointer;
}

.SelectPaymentMethod .PaymentMethodList:focus {
  outline: none;
}

.SelectPaymentMethod .SelectWrapper {
  flex: 1;
}

.SelectPaymentMethod .SelectWrapper::after {
  position: relative;
  content: 'Change';
  color: var(--color-text-hyperlink);
  font-size: inherit;
  font-family: 'Gill Sans Roman';
  top: 0;
  right: unset;
}

.SelectPaymentMethod .SelectWrapper:hover::after {
  text-decoration: underline;
}

.SelectPaymentMethod .PaymentMethodList.isPaymentMethodSelected {
  padding-right: 0px;
  letter-spacing: 3px;
}

.SelectPaymentMethod .Button {
  float: right;
}

.SelectPaymentMethod .PaymentMethodList.newPaymentMethod {
  padding-left: 0px;
  letter-spacing: normal;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .SelectPaymentMethod {
    margin: 8px 0;
  }
}
