.IngredientCategories {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.IngredientCategories>div {
  flex:1 1 0;
}

.IngredientCategoryTitle {
  text-align: center;
}
