.DiningChoiceContainer {
  width: 100%;
  margin-top: 20px;
}

.DiningChoiceContainer .tabsRow {
  display: flex;
  width: 100%;
}

.DiningChoiceContainer .Pickup,
.DiningChoiceContainer .Delivery {
  background-color: var(--color-content);
  clip-path: inset(0px -5px -5px -5px);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.16);
  border-radius: 0 0 10px 10px;
}

.DiningChoiceContainer .tabsRow .Button {
  font-size: 20px;
  font-weight: bold;
  padding: 14px 20px;
  margin-top: 8px;
  flex: 1;
  border-radius: 10px 10px 0 0;
  clip-path: inset(-5px -5px 0px -5px);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.16);
}

.DiningChoiceContainer .tabsRow .Button.selected,
.DiningChoiceContainer .tabsRow .Button.selected:focus {
  background-color: var(--color-content) !important;
  padding: 20px;
  margin-top: 0px;
  border: none !important;
  color: var(--color-text-primary);
}

.DiningChoiceContainer .tabsRow .Button.selected > span {
  padding-top: 10px;
}
