.UpsellScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.UpsellContent {
  flex: 1;
  padding: 8px 0 24px;
  overflow-y: auto;
}

.UpsellCategoryRow {
  flex-direction: column;
}

.UpsellCategoryName {
  text-align: center;
  padding: 8px 24px;
}

.UpsellSection {
  padding: 0 24px;
  overflow-x: auto;
  text-align: center;
}

.UpsellMenusRow {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Accomodate maximum of 6 menu items */
  max-width: 1250px;
}

.UpsellScreen .ActionButton .Button.dim {
  background-color: var(--color-secondary);
}

/* For tablet only */
@media (max-width: 992px) and (min-width: 768px) {
  .UpsellContent {
    padding-bottom: 72px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .UpsellScreen {
    background-color: var(--color-content);
    padding-bottom: 72px;
  }

  .UpsellContent {
    background-color: var(--color-modal-cart);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 2px 3px #00000029;
    overflow-y: initial;
  }

  .UpsellCategoryName,
  .UpsellSection {
    text-align: left;
  }

  .UpsellMenusRow {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    max-width: none;
  }
}
