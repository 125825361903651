.Pickup {
  padding: 24px 24px 20px 24px;
}

.Pickup .GglocationsSelect {
  max-width: unset;
  width: 100%;
  margin: 0;
  text-align-last: center;
  padding-left: 40px;
  font-family: 'Gill Sans Bold';
}

.Pickup .GglocationsSelect .Select {
  border-radius: 10px;
}

.Pickup .Button.btn-primary {
  height: 55px;
  border-radius: 10px;
  width: 100%;
}
