.PromoCodeInputWrapper {
  margin-top: 6px;
}

.PromoCodeInputWrapper .copy {
  font-size: 14px;
  color:#7D7769;
  text-align: left;
}

.PromoCodeInputMain {
  display: flex;
  align-items: center;
}

.PromoCodeInputMain > .Button.btn-primary {
  font-size: 0.9em;
  padding: 0px 30px;
  border-radius: 10px;
}

.PromoCodeInputMain .PromoCodeInput {
  position: relative;
  display: flex;
  flex: 1;
  margin-right: 15px;
}

.PromoCodeInputMain .PromoCodeInput .Input {
  position: relative;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
}

.PromoCodeInputMain .PromoCodeInput .Input.noValue {
  padding-left: 28px !important;
}

/* Layering buttons and input */
.PromoCodeInputMain .PromoCodeInput .Input {
  z-index: 1;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  z-index: 2;
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  z-index: 4;
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  position: absolute;
  left: 10px;
  top: 15px;
  color: var(--color-secondary);
}

.PromoCodeInputMain.hasError .PromoCodeInput .PlaceholderIcon {
  color: #D85A5B;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  position: absolute;
  right: 10px;
  top: 15px;
  color: var(--color-secondary);
}

.PromoCodeInputMain.hasError .PromoCodeInput .ClearButton {
  color: var(--color-text-error);
}

.PromoCodeInputMain>.Button {
  padding: 0 12px;
  height: 40px;
}

.PromoCodeInputWrapper .PromoCodeError {
  margin-top: 10px;
  color: var(--color-text-error);
  text-align: left;
  padding-left: 10px;
}

.PromoCodeInputWrapper .PromoCodeError .lnr {
  margin-right: 5px;
}
