.Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 0 8px;
  border-radius: 5px;
}

.Badge.Primary {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
}

.Badge.Secondary {
  background-color: var(--color-secondary);
  color: var(--color-text-contrast);
}

.Badge.Success {
  background-color: var(--color-light);
  color: var(--color-text-contrast);
}

.Badge.Error {
  background-color: var(--color-text-error);
  color: var(--color-text-contrast);
}

.Badge.Disabled {
  background-color: #8CA1A5;
  color: var(--color-text-contrast);
}
