.CollectionModal .modal-body {
  display: flex;
  background-color: var(--color-content);
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
}

.CollectionModal .modal-control {
  position: absolute;
}

.CollectionModal .ImageContainer {
  flex: 4;
}

.CollectionModal .ImageContainer img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.CollectionModal .Content {
  flex: 5;
  padding-bottom: 8px;
}

.CollectionModal .Content .Header {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.CollectionModal .TextContainer {
  text-align: left;
  padding: 16px;
}

.CollectionModal .ProgressBarBackground {
  height: 16px;
  border-radius: 10px;
  margin: 8px auto;
}

.CollectionModal .ProgressBar {
  height: 16px;
  background-color: #f2c241;
  border-radius: 10px;
}
