.TimeSlotPicker {
  padding: 16px 0;
}

.TimeSlotPicker .content-main {
  padding-top: 0px;
}

ul.picker-day {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  text-align: center;
  position: relative;
  margin-top: 15px;
  height: 50px;
  display: flex;
}
ul.picker-day li {
  display: inline-block;
  height: 50px;
  font-size: 1em;
  padding: 5px 0;
  flex: 1;
}
ul.picker-day li.ActiveDate {
  border-bottom: solid 3px var(--color-light);
}
ul.picker-day li > div,
ul.picker-day li > div > .Button,
ul.picker-day li > div > .Button > span {
  width: 100%;
}
ul.picker-day li > div > .Button > span {
  display: flex;
}
ul.picker-day li > div > .Button > span > .TimeSlotDate {
  flex: 1;
}
ul.picker-day li.PreviousDate > div > .Button > span > .lnr {
  margin-left: 5px;
}
ul.picker-day li.NextDate > div > .Button > span > .lnr {
  margin-right: 5px;
}
ul.picker-day li .lnr {
  line-height: 40px;
  font-size: 1.4em;
}
ul.picker-time {
  padding: 25px 32px;
  width: 100%;
  margin: auto;
}
ul.picker-time li {
  display: inline-flex;
  width: 45%;
  height: 52px;
  margin: 8px 1.5%;
}
ul.picker-time li a {
  color: inherit;
  background-color: #faf7f4;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 0 30px;
  position: relative;
  text-align: center;
}

.IconSlotLow {
  position: absolute;
  right: 5px;
  top: 10px;
}

ul.picker-time li a time {
  line-height: 52px;
}

ul.picker-day .Day {
  font-size: 1em;
  margin-bottom: 0;
}

ul.picker-day .Date {
  font-size: 0.7em;
  margin-bottom: 0;
}

ul.picker-day li.ActiveDate .Day {
  color: #92d27f;
}

ul.picker-day li.ActiveDate .Date {
  color: #92d27f;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  ul.picker-time li {
    display: inline-flex;
    width: 47.3%;
    height: 52px;
    margin: 8px 1%;
  }
}
