.OrderItem {
  margin-bottom: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  background-color: var(--color-panel-primary);
  position: relative;
  width: 100%;
  display: block;
}

.OrderItem ul {
  display: inline-flex;
  width: 100%;
}

.OrderItem>ul>li:first-child {
  background-color: #FFFFFF;
  width: 23%;
  height: auto;
  position: relative;
  display: block;
}

.OrderItem >ul>li:first-child button{
  background-color: #FFFFFF;
  width: 100%;
  padding: 8px;
  color: #95989A;
  border-top: 1px solid #F2EDE9;
  font-size: 0.9em;
  position: absolute;
  bottom: 0;
}

.OrderItem>ul>li:first-child .order-img{
  width: 100%;
  vertical-align: middle;
  min-height: 110px;
  overflow: hidden;
  position: relative;

}

.OrderItem .order-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: -5px;
}

.OrderItem>ul>li:nth-child(2) {
  width: 77%;
  padding: 8px 16px 8px 16px;
  display: block;
}

.OrderItem>ul>li:nth-child(2) h5 {
  font-size: 1.1em;
  margin-bottom: 6px;
}

.OrderItem .OrderItemIngredients {
  font-size: 0.85em;
  font-style: italic;
  padding-bottom: 20px;
}

.OrderItem>ul>li:nth-child(2) ul {
  font-size: 0.9em;
  width: 77%;
  z-index: 10000;
  position: relative;
  padding: 0 15px 10px 15px;
  position: absolute;
  bottom: 0;
  left: 23%;
  font-size: 0.85em;
}

.OrderItem>ul>li:nth-child(2) ul li:nth-child(1) {
  padding-right: 10px;
}

.OrderItem>ul>li:nth-child(2) ul li.Label {
  text-overflow: ellipsis;
  max-width: 62%;
  white-space: nowrap;
  overflow: hidden;
}

.OrderItem>ul>li:nth-child(2) ul li.Label::before {
  content: 'Label: ';
  color: #7D7769;
}

.OrderItem>ul>li:nth-child(2) ul li span {
  margin-right: 5px;
}

.OrderItem>ul>li:nth-child(2) ul li:last-child {
  text-align: right;
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.OrderItem .isFavorite .lnr-heart {
  color: #FF0000;
}

.OrderItem p span {
  display: inline;
}

.OrderItem.Small {
  text-align: left;
}

.OrderItem.Small>ul>li:first-child {
  width: 68px;
  display: flex;
  align-items: center;
}

.OrderItem.Small>ul>li:nth-child(2) {
  width: calc(100% - 68px);
}

.OrderItem.Small>ul>li:nth-child(2) h5 {
  font-size: 1em;
  line-height: 1.6em;
  margin-bottom: 1px;
  margin-top: 0;
}

.OrderItem.Small p {
  font-size: 0.8em;
  line-height: 1.4em;
  margin-bottom: 3px;
}

.OrderItem.Small>ul>li:nth-child(2) ul {
  display: none;
}

.OrderItem .FavoriteCaption {
  margin-left: 3px;
}

.OrderItem.Small .FavoriteCaption {
  display: none;
}

.OrderItem.Small>ul>li:nth-child(3) {
  width: 45px;
  border-left: 1px solid #E2DFD8;
}

.OrderItem.Small>ul>li:nth-child(3) .Button {
  width: 100%;
  height: 100%;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderItem > ul > li:first-child {
    width: 30%;
  }

  .OrderItem>ul>li:nth-child(2) {
    width: 70%;
  }

  .OrderItem>ul>li:nth-child(2) ul {
    width: 70%;
    left: 30%;
  }
}

/*--- Mobile Landscape and Portrait --*/
@media (max-width: 767px) {
  .OrderItem > ul > li:first-child {
    width: 33%;
  }

  .OrderItem>ul>li:first-child button{
    padding: 12px;
  }

  .OrderItem>ul>li:nth-child(2) {
    width: 67%;
  }
  
  .OrderItem>ul>li:nth-child(2) ul {
    width: 67%;
    left: 33%;
    font-size: 0.8em;
  }
}
