.LandingPage .LandingPageMenu {
  padding-top: 16px;
}

.LandingPage .MenuItem {
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
}

.LandingPageMenu .MenuItem h5 {
  font-weight: bold;
}

.LandingPageMenu .category-name h3 {
  font-weight: bold;
}
