.IngredientAddWrapper {
  display: flex;
  flex-direction: column;
  width: 90px;
  max-width: 115px;
  min-height: 140px;
}
.IngredientAdd {
  margin-bottom: 10px;
  border: 1px solid #A8B3A4;
  border-radius: 5px;
  text-align: center;
  height: 136px;
  flex: 1;
}
.IngredientAdd:hover, .IngredientAdd:focus {
  background-color: rgba(168, 179, 164, 0.25);
  border: 1px solid #A8B3A4;
}
.IngredientAdd img{
    width: 38px;
    padding: 30% 0 10px 0;
}
.IngredientAddCaption {
  font-size: 0.7em;
  width: 40px;
  margin: auto;
  color: var(--color-text-primary);
  line-height: 1.5em;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) and (min-width: 480px) {
  .IngredientAddWrapper {
    min-height: 150px;
  }
}

@media(max-width: 479px) {
  .IngredientAddWrapper {
    width: 30%;
  }
}
