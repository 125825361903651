.PaymentMethod {
  padding-bottom: 8px;
}
.PaymentMethod ul{
  display: flex;
  justify-content: center;
  background-color: var(--color-content);
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  padding-left: 10px;
  border-radius: 5px;
  width: 53%;
  color: var(--color-text-primary);
  height: 50px;
}
.PaymentMethod .trashButton {
  flex: 1;
}

.PaymentMethod .defaultText {
  flex: 5;
  justify-content: flex-start;
  font-size: 14px;
  padding-left: 10px;
}

.cc-list .setDefaultPaymentMethodButton {
  flex: 5;
  justify-content: flex-start;
  font-size: 14px;
  color: var(--color-text-hyperlink);
  padding-left: 10px;
}
.cc-list .setDefaultPaymentMethodButton>button>span {
  justify-content: flex-start !important;
}
.cc-list .paymentMethodLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-primary);
}

@media (max-width: 992px) {
  .PaymentMethod>ul{
    width: 100%;
  }
}
