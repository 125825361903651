.panel-heading ul {
  display: flex;
}

.panel-heading p {
  margin-bottom: 0;
}

.form-editable {
  width: 80%;
  max-width: calc(100% - 86px);
}

.form-editable p {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
}
