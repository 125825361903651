.IngredientModal .modal-dialog {
  width: 80% !important;
  max-width: 1000px !important;
}

.IngredientModal .modal-body {
  display: flex;
  text-align: left;
  padding: 0;
  font-size: 0.95em;
}

.IngredientModal .modal-content {
  top: 8vh !important;
  border-radius: 6px !important;
}

.IngredientModal .modal-control {
  position: absolute;
}

.IngredientModal .modal-body p {
  padding-left: 15px;
  color: #989898;
  margin-bottom: 5px;
}

.IngredientModal .modal-body ul {
  padding-left: 15px;
  display: flex;
}

.IngredientModal .modal-body ul li {
  display: inline-flex;
  flex: 1;
  padding: 3px 0;
}

.IngredientModal .ServingWeight {
  margin-bottom: 16px;
}

.IngredientModal .ServingWeight li:first-child {
  flex: none;
  width: 115px;
}

.IngredientModal .ServingWeight {
  margin-bottom: 16px;
}

.IngredientModal .ServingWeight {
  margin-bottom: 16px;
}

.IngredientModal .IngredientInfo {
  flex: 1;
}

.IngredientModal .IngredientDescription {
  width: 80%;
  color: var(--color-text-primary) !important;
  padding-bottom: 11px;
  text-align: justify;
}

.IngredientModal .NutrientsList {
  column-count: 2;
}

.IngredientModal h5 {
  padding-left: 15px;
  padding-top: 16px;
  padding-bottom: 22px;
}

.IngredientModalImage {
  width: 435px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden;
}

.IngredientModalImage img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.IngredientModal .NutritionalInformation,
.IngredientModal .modal-body .NutritionalDisclaimer {
  width: 80%;
  color: var(--color-text-primary);
  font-style: italic;
}

.IngredientModal .modal-body .NutritionalDisclaimer {
  margin-top: 8px;
}

@media(min-width: 992px) {
  .IngredientModal .NutrientsList ul li:first-child {
    flex: 2;
  }
}

@media(max-width: 1192px) and (min-width: 992px) {
  .IngredientModal .modal-body .NutrientName
  {
    width: 70px;
  }

  .IngredientModal .modal-body .NutrientValue {
    width: 50px;
  }
}

@media(max-width: 1192px) and (min-width: 992px) {
  .IngredientModal .modal-body .NutrientName
  {
    width: 70px;
  }

  .IngredientModal .modal-body .NutrientValue {
    width: 50px;
  }
}

/*--- M1: tablet and mobile ---*/
@media (max-width: 992px) {
  .IngredientModal .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    padding: 0 27.5px;
  }

  .IngredientModal .IngredientInfo {
    padding: 0;
  }

  .IngredientModal .modal-content {
    top: 0vh !important;
  }

  .IngredientModal .modal-body {
    flex-direction: column;
  }

  .IngredientModal .modal-body .IngredientModalImage {
    width: 100%;
    height: 20vh;
    background-color: #FFFFFF;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0px;
    overflow: hidden;
    text-align: center;
  }

  .IngredientModal .modal-body .IngredientModalImage img {
    height: 100%;
    object-fit: cover;
  }

  .IngredientModal .modal-body .IngredientInfo {
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
  }

  .IngredientModal .modal-body .IngredientInfo ul {
    padding-left: 15px;
  }

  .IngredientModal .modal-body .IngredientInfo ul li {
    padding: 0;
  }

  .IngredientModal .modal-body .IngredientInfo ul li:nth-child(2) {
    font-weight: 400;
  }

  .IngredientModal .modal-body .NutritionalDisclaimer {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 15px;
    line-height: 1.2em;
  }

  .IngredientModal .IngredientDescription {
    width: 100%;
    padding-right: 10px;
  }
}

@media (max-width: 479px) {
  .IngredientModal .NutrientsList {
    column-count: 1;
  }

  .IngredientModal .IngredientDescription {
    width: 100%;
    padding-right: 10px;
  }
}

@media (max-width: 454px) {
  .IngredientModal .modal-body ul li:first-child {
    width: 80px;
  }

  .IngredientModal .IngredientDescription {
    width: 100%;
    padding-right: 10px;
  }
}

@media (max-width: 454px) {
  .IngredientModal .modal-body ul li:first-child {
    width: 80px;
  }
}
