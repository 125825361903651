.PastOrderHeader {
  width: 100%;
  display: flex;
}

.PastOrderHeader .Body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PastOrderHeader .Details {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.PastOrderHeader .Body .Date {
  color: #958f88;
  font-size: 0.8em;
  margin-bottom: 4px;
}

.PastOrderHeader .Body .GglocationName {
  font-family: 'Gill Sans Bold';
  font-size: 0.95em;
  color: var(--color-text-primary);
}

.PastOrderHeader .Body .GglocationName .OrderNumber {
  color: #958f88;
}

.PastOrderHeader .Price {
  font-size: 1em;
  color: var(--color-text-primary);
}

.PastOrderHeader .Price .DollarSign {
  font-size: 0.8em;
  margin-right: 4px;
}

.PastOrderHeader .Price .fa {
  margin-left: 8px;
  vertical-align: text-bottom;
  color: var(--color-secondary);
}

.PastOrderHeader .Price a {
  display: initial;
}

.PastOrderHeader .Status {
  flex: 1;
  font-size: 14px;
  color: var(--color-text-contrast);
}

.PastOrderHeader .Status span {
  padding: 2px 5px;
  border-radius: 3px;
}

.PastOrderHeader .Status .Pending {
  background-color: var(--color-light);
}

.PastOrderHeader .Status .Cancelled {
  background-color: #8ca1a5;
}

.PastOrderHeader .Status .Ready {
  background-color: var(--color-primary);
}

.PastOrder .PastOrderItems {
  font-size: 0.95em;
  color: var(--color-text-primary);
}

.PastOrderFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px -15px -15px -15px;
  padding: 8px 16px 16px;
  border-top: solid 1px #dddddd;
}

.PastOrderFooter .Button.btn-primary {
  padding: 8px 24px;
  float: right;
}
