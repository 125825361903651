/* .all-store */
.GglocationsList {
  padding: 0 12px 0 25px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
}
/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .all-store */
  .GglocationsList {
    padding: 0;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  /* .all-store */
  .GglocationsList .store-list {
    padding: 10px 25px 0 25px;
  }
}

/*--- Mobile Landscape and Portrait only ---*/
@media (max-width: 767px) {
  /* .all-store */
  .GglocationsList .store-list {
    padding: 10px 16px 0 16px;
  }
}
