.Menu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.Menu .SelectedRewardWrapper {
  max-width: 600px;
  margin: 20px auto;
}

.Menu .LoyaltyRewardsList {
  margin-top: 10px;
}

.Menu .MenuContentWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
}

.Menu .LoyaltyRewardWrapper {
  max-width: 600px;
}

.section {
  clear:both;
  text-align: center;
  margin-bottom: 10px;
}
