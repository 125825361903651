.ButtonsRow, .ButtonsRow > div, .ButtonsRow > span {
  display: flex;
  flex: 1;
}

.ButtonsRow .Button {
  flex: 1;
  padding: 15px;
  margin: 0 10px;
}

.ButtonsRow .Button:first-child {
  margin-left: 0;
}

.ButtonsRow .Button:last-child {
  margin-right: 0;
}
