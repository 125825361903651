/* .store-container */
.GglocationLocator {
  display: flex;
  overflow: auto;
}

.StoreContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.StoreContainer .Disclaimer {
  padding: 5px 10px 10px;
  text-align: center;
  color: var(--color-primary) !important;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .store-container */
  .StoreContainer .Disclaimer {
    padding: 20px 10px 0;
  }
}
