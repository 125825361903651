.Order {
  overflow: auto;
}

.Order li.column > div {
  margin-bottom: 24px;
}

.Order .FeedbackSticker {
  width: calc(100% - 20px) !important;
  margin: 10px;
}

.Order .time,
.Order .order {
  margin-top: 32px;
}

.Order .time-confirmed,
.Order .time-cancelled {
  margin-top: 15px;
}

.Order .time img {
  width: 20px;
  margin-right: 8px;
}

.Order .time p {
  width: 50%;
  font-size: 1.1em;
  margin: 0 auto;
}

.Order .code h3 {
  font-size: 2.3em;
  margin-top: 5px;
}

.Order .order {
  margin-bottom: 100px;
}

.Order .order > ul > li > p {
  margin-bottom: 16px;
}

.Order .order > ul:first-child {
  position: relative;
  text-align: center;
  width: 80%;
  max-width: 480px;
  margin: 0 auto;
}

.Order .order > ul:first-child p {
  font-size: 0.95em;
  color: #7d7769;
}

.Order .order > ul li {
  display: inline-block;
  vertical-align: text-top;
}

.OrderItems {
  width: 80%;
  max-width: 480px;
  display: block;
  margin: 0 auto 10px;
  text-align: left;
}

.Order .cancel {
  text-align: center;
  width: 100%;
  margin: 0px auto;
}

.Order .cancel p {
  color: #7D7769;
  font-size: 0.8em;
  margin-top: 8px;
}

.Order .time-confirmed > ul,
.Order .time-cancelled > ul {
  padding-top: 10px;
}

.Order .time-confirmed > ul li {
  display: inline-block;
  font-size: 1.3em;
}

.Order .time-confirmed > ul li:first-child {
  text-align: right;
  padding-right: 35px;
}

.Order .time-confirmed > ul li:last-child {
  text-align: left;
  padding-left: 35px;
}

.Order .time-confirmed > ul li span {
  font-weight: 600;
}

.Order .reorder {
  text-align: center;
}

.Order .Disclaimer {
  padding: 0 32px;
  margin-top: 25px;
  font-size: 13.75px;
}

.Order .deliveryTips {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.Order .deliveryTips .Info {
  padding: 0 8px;
  align-self: end;
}

.Order .hotline {
  font-weight: 600;
  color: var(--color-text-primary);
  font-size: 14px;
  margin-top: 16px;
}

.Order .hotline .hotlineNumber {
  text-decoration: underline;
  color: var(--color-text-hyperlink);
}

.Order .ContentSeparator.kale li {
  width: 100%;
}

.Order .ContentSeparator.kale p {
  background-color: transparent;
  font-size: 16px;
  color: #525252;
  font-weight: 600;
  margin-bottom: 16px;
}

.Order .ContentSeparator .line {
  margin-top: 31px;
}

.Order .store {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Order .store .GglocationInfo {
  padding: 0 32px;
}

.Order .GglocationIcon.content-main {
  width: 100%;
}

.Order .hidden-xs.orderAddressSticker {
  width: 100%;
}

.Order .payment,
.Order .visible-xs.orderAddressSticker {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.Order .visible-xs.orderAddressSticker .AddressNameLine h3 {
  font-size: 16px;
  padding-bottom: unset;
}

.Order .visible-xs.orderAddressSticker .ContentSeparator.kale {
  margin-bottom: -40px;
}

.Order .visible-xs.orderAddressSticker .GglocationInfo {
  padding: 12px 24px 0;
}

.Order .deliveryTips {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.Order .deliveryTips .Info {
  padding: 0 8px;
  align-self: end;
}

.Order .hotline {
  font-weight: 600;
  color: var(--color-text-primary);
  font-size: 14px;
  margin-top: 16px;
}

.Order .hotline .hotlineNumber {
  text-decoration: underline;
  color: var(--color-text-hyperlink);
}

.Order .ContentSeparator.kale li {
  width: 100%;
}

.Order .ContentSeparator.kale p {
  background-color: transparent;
  font-size: 16px;
  color: #525252;
  font-weight: 600;
  margin-bottom: 16px;
}

.Order .ContentSeparator .line {
  margin-top: 31px;
}

.Order .store {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Order .store .GglocationInfo {
  padding: 0 32px;
}

.Order .GglocationIcon.content-main {
  width: 100%;
}

.Order .hidden-xs.orderAddressSticker {
  width: 100%;
}

.Order .payment,
.Order .visible-xs.orderAddressSticker {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.Order .visible-xs.orderAddressSticker .AddressNameLine h3 {
  font-size: 16px;
  padding-bottom: unset;
}

.Order .visible-xs.orderAddressSticker .ContentSeparator.kale {
  margin-bottom: -40px;
}

.Order .visible-xs.orderAddressSticker .GglocationInfo {
  padding: 12px 24px 0;
}

.Order .deliveryTips {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.Order .deliveryTips .Info {
  padding: 0 8px;
  align-self: end;
}

.Order .hotline {
  font-weight: 600;
  color: var(--color-text-primary);
  font-size: 14px;
  margin-top: 16px;
}

.Order .hotline .hotlineNumber {
  text-decoration: underline;
  color: var(--color-text-hyperlink);
}

.Order .ContentSeparator.kale li {
  width: 100%;
}

.Order .ContentSeparator.kale p {
  background-color: transparent;
  font-size: 16px;
  color: #525252;
  font-weight: 600;
  margin-bottom: 16px;
}

.Order .ContentSeparator .line {
  margin-top: 31px;
}

.Order .store {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Order .store .GglocationInfo {
  padding: 0 32px;
}

.Order .GglocationIcon.content-main {
  width: 100%;
}

.Order .hidden-xs.orderAddressSticker {
  width: 100%;
}

.Order .payment,
.Order .visible-xs.orderAddressSticker {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.Order .visible-xs.orderAddressSticker .AddressNameLine h3 {
  font-size: 16px;
  padding-bottom: unset;
}

.Order .visible-xs.orderAddressSticker .ContentSeparator.kale {
  margin-bottom: -40px;
}

.Order .visible-xs.orderAddressSticker .GglocationInfo {
  padding: 12px 24px 0;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .Order .time,
  .Order .order {
    margin-top: 45px;
  }

  .Order .time p {
    width: 80%;
  }

  .Order .store .store-info {
    width: 85%;
    max-width: 360px;
  }

  .Order .order > ul:first-child {
    width: 100%;
  }

  .Order .OrderItems {
    width: 90%;
    max-width: 400px;
  }

  .Order .cancel {
    margin: 20px auto;
  }
}

/*--- Mobile Landscape and Portrait --*/
@media (max-width: 767px) {
  .Order .time {
    margin-top: 28px;
  }

  .Order .order {
    margin-top: 0px;
  }

  .Order .OrderItems {
    max-width: unset;
    margin: 0 24px;
    width: 90%;
  }

  .Order .order .ContentSeparator .line {
    margin-top: 16px;
  }

  .Order .time img {
    width: 16px;
    position: relative;
    top: -2px;
  }

  .Order .time p {
    width: 80%;
  }

  .Order .store .store-info {
    width: 90%;
    max-width: 440px;
  }

  .Order .code h3 {
    font-size: 2em;
  }

  .Order .order {
    margin-bottom: 100px;
  }

  .Order .hotline {
    margin-top: 0;
  }

  .Order .cancel {
    margin: 15px auto;
    padding: 0 0 40px 0;
  }

  .Order .cancel button {
    margin-top: 20px;
  }

  .Order .deliveryTips {
    width: 100%;
    padding: 0 24px;
  }

  .Order .Disclaimer {
    padding: 0 24px;
    margin-top: 25px;
    font-size: 13.75px;
  }

  .Order .payment {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .Order .time-confirmed > ul {
    width: 80%;
    margin: auto;
    font-size: 1em;
    padding: 20px 0 10px 0;
  }

  .Order .time-confirmed > ul li {
    width: 49%;
  }

  .Order .time-confirmed > ul li:first-child {
    text-align: left;
    padding-right: 0;
  }

  .Order .time-confirmed > ul li:last-child {
    text-align: right;
    padding-left: 0;
  }
}
