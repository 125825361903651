.Button.btn-facebook {
  background-color: #3B5998 !important;
  border: 1px solid #3B5998 !important;
  position: relative;
}
.Button.btn-facebook:hover {
  background-color: #325191 !important;
  border: 1px solid #325191 !important;
}
.Button.btn-facebook:focus {
  background-color: #325191 !important;
  border: 1px solid #325191 !important;
}
.Button.btn-facebook .fa-facebook {
  font-size: 1.8em;
  position: absolute;
  left: 14px;
  top: 9px;
}

.SignInLogos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
