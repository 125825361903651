.CartPriceLine {
  width: 100%;
  display: flex;
}

.CartPriceLineTitle {
  display: flex;
  margin-bottom: 0px;
}

.CartPriceLineTitle .Button {
  display: inline-block;
  margin-left: 5px;
  color: #90B797;
}

.CartPriceLine li {
  display: inline-flex;
  vertical-align: text-top;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.CartPriceLine li p {
  margin-bottom: 0;
}

.CartPriceLine li:first-child {
  text-align: left;
  flex: 1;
}

.CartPriceLine li:last-child {
  text-align: right;
}

.CartPriceLine.CartTotal {
  margin-top: 4px;
}

.Tax {
  color: var(--color-secondary);
}
