.ErrorSticker {
  background-color: #FFD5D5;
  border: 1px solid #D27F7F;
  border-radius: 5px;
  color: var(--color-text-error);
  padding: 8px 16px;
  margin-bottom: 18px;
}
.ErrorSticker p {
  margin-bottom: 0;
}
.ErrorSticker p:nth-child(n+2) {
  margin-top: 8px;
}
.ErrorSticker .lnr {
  font-size: 1.5em;
  margin-right: 8px;
  position: relative;
  top: 4px;
}
