.CyoBody {
  overflow: auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.CyoIngredientsPicker {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.CyoIngredientsPicker.isHeaderShown .CyoBody {
  flex: 1 1 auto;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  /*
    iOS devices only.
    100vh on iOS is not equivalent to the full available viewport, instead it includes 75px high
    navigation bars on both sides.
  */
  .CyoIngredientsPicker .IngredientCategories {
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 16px;
    padding-bottom: 80px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .App:not(.isWebView) .CyoIngredientsPicker.isHeaderShown .CyoBody {
      max-height: calc(100vh - 45px - 54px - 54px - 75px);
    }
  }
}
