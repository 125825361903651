.DietButton {
  font-size: 0.85em;
  position: fixed;
  display: block;
  width: 64px;
  height: 64px;
  top: auto;
  bottom: 15px;
  text-align: center;
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  right: 12px;
  border-radius: 32px;
  padding: 18px 0;
  z-index: 9999;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
}

.DietButton button span {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 28px;
}
