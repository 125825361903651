.QuantitySelector {
  width: 100%;
  background-color: var(--color-content);
  padding: 2px;
}

.QuantitySelector .section {
  max-width: 600px;
  position: relative;
  padding-bottom: 0px;
  height: 100%;
  display: flex;
}

.QuantitySelector ul {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.QuantitySelector ul li.counter ul li:first-child,
.QuantitySelector ul li.counter ul li {
  display: flex;
  align-items: center;
}

.QuantitySelector ul li.counter ul li:nth-child(2) {
  padding: 0 32px;
}

.QuantitySelector ul li.counter button {
  opacity: 0.7;
}

.QuantitySelector ul li.counter button:hover {
  opacity: 1;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .QuantitySelector {
    width: 100%;
    background-color: var(--color-content);
  }

  .QuantitySelector .section {
    margin: auto;
    padding: 6px 16px;
  }
}
