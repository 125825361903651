/* Desktop Only */
@media (min-width: 993px) {
  .GglocationMapMobileWrapper {
    display: none;
  }
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .store-map-mb */
  .GglocationMapMobileWrapper {
    display: flex;
    flex: 1;
    outline: none;
  }

  .GglocationMapMobile {
    display: flex;
    flex: 1;
  }

  .GglocationMapMobileWrapper.expanded .GglocationMapMobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .GglocationMapMobileCloseButtonWrapper {
    display: none;
    width: 40px;
  }

  .GglocationMapMobileWrapper.expanded .GglocationMapMobileCloseButtonWrapper {
    display: block;
    position: fixed;
    top: 14px;
    left: 5px;
    z-index: 9999999;
    background: var(--color-primary);
    color: white;
    font-size: 1.8em;
  }
}
