.img-home {
  height: 68vh;
  min-height: 415px;
}
.img-home img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  min-height: 415px;
}
.img-home h2 {
  font-size: 4.4em;
  line-height: 1.3em;
  position: relative;
  top: -280px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  width: 3em;
  text-align: center;
  margin: auto;
}
.content-home {
  width: 32vw;
  min-width: 300px;
  height: 32vh;
  margin: auto;
  padding-top: 30px;
}
.content-mobile {
  text-align: center;
  padding-top: 6px;
  max-width: 90%;
  margin: auto;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  border-top: 1px solid #ffffff;
  padding-top: 25px;
  clear: both;
}
.footer ul li {
  display: inline-block;
  padding: 10px;
}
.footer ul li a {
  color: var(--color-text-primary);
}
.footer ul li a:hover {
  text-decoration: underline;
}
.footer ul.social-link li a {
  font-size: 1.9em;
  padding: 10px;
}
.footer ul.cpr-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer ul.cpr-msg li {
  color: #95989a;
  padding: 3px;
  vertical-align: baseline;
}
.footer ul.cpr-msg li span {
  padding-right: 4px;
}
.footer ul.cpr-msg li img {
  height: 12px;
  width: auto;
  position: relative;
  top: -3px;
}
.footer ul.cpr-msg p {
  margin: 0;
}
.LandingLeftContainer {
  display: flex;
  flex: 2;
  overflow: hidden auto;
  border-right: 1px solid #ffffff;
  text-align: center;
  position: sticky;
  -webkit-position: sticky;
  max-height: 800px;
}

.LandingLeftContainer h2 {
  position: absolute;
  top: 45%;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  color: var(--color-text-contrast);
  width: 100%;
  text-align: center;
}
.LandingLeftContainer .TitledMenuSection {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.LandingLeftContainer .TitledMenuSection .section {
  overflow-x: auto;
}
.LandingLeftContainer .TitledMenuSection .category-desc {
  margin: 0;
}
.LandingLeftContainer .TitledMenuSection .MenuCategory {
  margin-top: 0px;
}
.LandingLeftContainer .TitledMenuSection .MenuCategory.scrollable {
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: auto;
  overflow-x: auto;
  padding-right: 8px;
}
.LandingRightContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  float: right;
  overflow-y: auto;
}
.LandingRightContainer .user-top {
  display: block;
  vertical-align: bottom;
}
.LandingRightContainer table.separator {
  text-align: center;
  display: table;
  width: 100%;
  height: 30px;
  position: relative;
}

.LandingRightContainer .ContentSeparator p {
  font-size: 1em;
  color: inherit;
}

.LandingRightContainer table.separator td {
  display: table-cell;
  color: var(--color-text-primary);
  vertical-align: middle;
}
.LandingRightContainer table.separator td:first-child,
.LandingRightContainer table.separator td:last-child {
  width: 50%;
  position: relative;
}
.LandingRightContainer table.separator td:first-child > div,
.LandingRightContainer table.separator td:last-child > div {
  height: 1px;
  border-top: 1px solid #ffffff;
}

.LandingRightContainer table.separator td:nth-child(2) {
  white-space: nowrap;
  padding: 10px 10px 0 10px;
}
.LandingRightContainer .user-location-list {
  padding: 0 20px;
}
.LandingRightContainer .user-location {
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
  right: 0;
  left: 0;
}
.LandingRightContainer .user-order {
  width: 100%;
  text-align: center;
  color: #3c6242;
  background-color: #ffffff;
  border-top: 1px solid #ffffff;
  padding-top: 14px;
  padding-bottom: 10px;
}
.LandingRightContainer .user-order .SectionSeparator {
  margin: 6px 0;
}
.LandingRightContainer .LandingCurrentOrder {
  margin: 10px 49px;
  width: auto;
  display: flex;
  flex-direction: column;
}

.LandingRightContainer .LandingCurrentOrder ul.order-header {
  background-color: var(--color-text-primary);
  display: flex;
  align-items: center;
  height: 3.1em;
  padding: 16px;
}
.LandingRightContainer .LandingCurrentOrder ul.order-header li {
  display: flex;
  flex: 1 1 0;
  color: var(--color-text-contrast);
}

.LandingRightContainer .LandingCurrentOrder ul.order-header li:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}

.LandingRightContainer .LandingCurrentOrder ul.order-header li:nth-child(2) p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.LandingRightContainer
  .LandingCurrentOrder
  ul.order-header
  li:nth-child(2)
  .fromNow {
  border-left: 1px solid #92d27f;
  padding-left: 8px;
  margin-left: 6px;
}
.LandingRightContainer .LandingCurrentOrder .order-body {
  background-color: var(--color-content);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.LandingRightContainer .LandingCurrentOrder .order-body {
  text-align: left;
  padding: 12px 0;
}
.LandingRightContainer .LandingCurrentOrder .order-body > div {
  padding: 0px 16px 8px;
}
.LandingRightContainer .LandingCurrentOrder .order-body p.total {
  width: 100%;
  border-top: 1px solid #ffffff;
  padding-top: 8px;
  margin-top: 2px;
  text-align: right;
  padding-right: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.LandingRightContainer .LandingCurrentOrder .order-body p.total span.label {
  color: #7d7769;
  font-weight: 400 !important;
  margin-right: 6px;
}
.user-order > a {
  color: inherit;
}

.Landing {
  display: flex;
  flex: 1;
}

.LandingMainImage {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.LandingMainImage img {
  object-fit: cover;
}

.LandingLeftContainer .TitledMenuSection {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 black;
  max-height: 100%;
  background-color: rgba(60, 97, 66, 0.65);
  padding-top: 10px;
}

.LandingLeftContainer .TitledMenuSection .SectionSeparator {
  color: #ffffff !important;
}

.LandingLeftContainer .TitledMenuSection .SectionSeparator .line {
  border-top: 1px solid #ffffff;
}

.LandingLeftContainer .TitledMenuSection .section {
  padding: 0 10px;
}

.LandingLeftContainer .TitledMenuSection .MenuItem {
  width: calc(20% - 10px);
  max-width: 160px;
  min-width: 144px;
}

.LandingLeftContainer .TitledMenuSection .MenuItem .menu-image img {
  height: auto;
  max-height: 160px;
}

.TitledMenuSection .section {
  margin-top: 6px;
}

.LandingWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

/*--- Only Desktop ---*/
@media (min-width: 1201px) {
  .img-home {
    margin-top: -54px;
  }
}

/*--- Small Desktop ---*/
@media (min-width: 991px) and (max-width: 1200px) {
  .img-home,
  .img-home img {
    height: 60vh;
  }
  .content-home {
    padding-top: 30px;
    height: calc(40vh - 56px);
  }
}
/*--- Tablet and Mobile ---*/
@media (max-width: 991px) {
  .LandingLeftContainer {
    display: none;
  }
  .LandingRightContainer {
    width: 100%;
    float: none;
    height: auto;
    min-height: calc(84vh - 56px);
  }
  .LandingRightContainer .user-top {
    display: block;
    height: auto;
  }
  .LandingRightContainer .user-order {
    position: relative;
    padding-bottom: 2px;
    max-height: none;
    display: block;
  }
  .LandingRightContainer .LandingCurrentOrder {
    width: auto;
    margin: 0 49px;
    position: relative;
    display: block;
  }

  .Landing {
    overflow-y: initial;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 991px) {
  .LandingRightContainer .LandingCurrentOrder {
    width: auto;
    margin: 0 49px;
    display: block;
  }
  .img-home,
  .img-home img {
    height: 60vh;
  }
  .content-home {
    height: 35vh;
  }
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .LandingRightContainer .LandingCurrentOrder {
    width: auto;
    margin: 10px 25px;
    display: block;
  }
  .img-home h2 {
    font-size: 3.6em;
  }

  .footer {
    padding-top: 10px;
  }

  .Landing .content-main {
    padding: 20px 0 0px 0 !important;
  }
}

/*--- Mobile Landscape Only ---*/
@media (max-width: 767px) and (min-width: 480px) {
  .img-home,
  .img-home img {
    height: 45vh;
    min-height: 120px;
  }
  .img-home h2 {
    width: 10em;
    top: -90px;
    margin-bottom: -45px;
  }
  .content-home {
    height: 44vh;
    width: 90vw;
    padding-top: 0px;
  }
  .center {
    margin: 10px auto;
  }
}

/*--- Mobile Portrait Only ---*/
@media (max-width: 479px) {
  .img-home,
  .img-home img {
    height: 50vh;
    min-height: 220px;
  }
  .img-home h2 {
    top: -220px;
    margin-bottom: -110px;
  }
  .content-home {
    height: 43vh;
    padding-top: 20px;
  }
}
