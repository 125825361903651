.CustomizeRight {
  display: flex;
  flex-direction: column;
  flex: 6;
  overflow-y: auto;
}

.CustomizeRight .section {
  text-align: left;
  padding: 2px 20px;
}
.CustomizeRight .section ul:first-child li {
  display: inline-block;
}

.MenuContentFrame {
  flex: 1;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.ActionButton .btn-next, .ActionButtonCyo .btn-next {
  width: 100%;
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  height: 54px;
  font-size: 1em;
}

.ActionButtonCyo .btn-next {
  color: var(--color-text-contrast);
}

.ActionButtonCyo .btn-energy {
  color: var(--color-text-contrast);
}

.ActionButton .btn-next .AttentionSeeker, .ActionButtonCyo .btn-next .AttentionSeeker {
  font-weight: 500;
}

.ActionButton .btn-next:hover {
  background-color: var(--color-dark);
  color: var(--color-text-contrast);
}

.ActionButton .AttentionSeeker, .ActionButtonCyo .AttentionSeeker {
  padding-left: 10px;
}

.ActionButton .btn-next {
  display: flex;
  align-items: center;
}

.ActionButton .btn-next>span {
  flex: 1;
  text-align: center;
}

.CustomizeRight .DietButton {
  position: fixed;
  bottom: 69px;
  z-index: 9998;
}

.CustomizeRight .DietSticker {
  position: absolute;
  bottom: 74px;
  z-index: 9998;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CustomizeRight {
    width: 100%;
  }
  .CustomizeRight .section {
    position: relative;
  }
  .CustomizeRight .section h5 {
    font-size: 1.3em;
  }
  .CustomizeRight .section li.icon-nutritional {
    margin-left: 10px;
    position: relative;
    top: -5px;
    left: 5px;
  }
  .CustomizeRight .section li.icon-nutritional ul li {
    width: 28px;
  }
  .CustomizeRight .section ul:nth-child(n+2) li.MenuCategory {
    width: 30%;
    max-width: 115px;
    margin: 0 12px 12px 0;
  }

  .CustomizeRight .section ul:nth-child(n+2) li.btn-add {
    width: 30%;
    max-width: 115px;
    margin: 0 0 12px 0;
    min-height: 150px;
  }

  .CustomizeRight .section ul:nth-child(n+1) li .item-name {
    height: auto;
    position: relative;
    padding-bottom: 0;
  }
  .CustomizeRight .section ul:nth-child(n+1) li .item-name p {
    transform: none;
    padding-top: 5px;
    padding-bottom: 0;
  }

  .CustomizeRight .section ul.dressing {
    position: relative;
    width: 100%;
  }

  .CustomizeRight .section ul.dressing li:nth-child(2) {
    position: absolute;
    right: -10px;
    top: -13px;
    text-align: right;
  }

  .CustomizeRight .section ul.dressing label {
    position: relative;
    top: 16px;
    margin-right: 6px;
  }
  .ActionButton .btn-next {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  
  .ActionButtonCyo {
    display: flex;
    flex-direction: column;
    height: 56px;
  }

  .ActionButtonCyo ul {
    display: flex;
    height: 100%;
  }

  .ActionButtonCyo ul li {
    display: flex;
    height: 100%;
  }

  .ActionButtonCyo ul li:first-child {
    flex: 9;
  }

  .ActionButtonCyo ul li:last-child {
    flex: 11;
  }

  .ActionButtonCyo .Button.btn-energy {
    background-color: var(--color-secondary);
    height: 100%;
    width: 100%;
    padding: 0 10px;
  }
  .ActionButtonCyo .Ingredients .lnr {
    position: relative;
    right: 5px;
  }
  .ActionButtonCyo .Button.btn-next {
    background-color: var(--color-primary);
    height: 100%;
    width: 100%;
    border-left: 2px solid #F2EDE9;
  }
  .ActionButtonCyo .Button.btn-next:hover {
    background-color: #315738;
  }
  .ActionButtonCyo .Button.btn-energy:hover {
    background-color: #819A9F;
  }
  .ActionButtonCyo .IngredientButtonText {
    flex: 1;
  }
  .ActionButtonCyo li span {
    font-weight: 400;
  }

  .CustomizeRight .DietSticker {
    position: fixed;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .CustomizeRight .section ul:nth-child(n+2) li.MenuCategory:nth-child(3n) {
    margin: 0 0 12px 0;
  }
}
