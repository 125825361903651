.MenuDetailsView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.MenuDetailsView .IngredientsPicker {
  padding: 2px 20px;
  width: 100%;
  text-align: left;
  justify-content: left;
}

/* Mobile Portrait and Landscape */
@media (max-width: 993px) {
  .MenuDetailsView {
    padding-bottom: 64px;
  }
}
