.DietModalButton {
  color: var(--color-primary);
  display: inline-flex;
  flex-basis: 30%;
  margin: 0 0 8px;
  align-items: center;
  height: 52px;
  padding: 12px;
}

.DietModalButton, .DietModalButton:focus {
  background-color: var(--color-content);
  border: 1px solid var(--color-content);
}

.DietModalButton img {
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .DietModalButton {
    flex-basis: 30%;
    font-size: 0.9em;
  }

  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
    margin-top: -5px;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModalButton {
    flex-basis: 47%;
    font-size: 0.9em;
  }

  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
  }
}
