.ErrorBoundary {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ErrorBoundary .ErrorBoundaryContent {
    width: 80%;
    max-width: 600px;
    text-align: center;
}

.ErrorBoundary .ErrorBoundaryContent .Buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ErrorBoundary .ErrorBoundaryContent .Buttons .Button {
    margin-right: 10px;
}

/* Mobile Landscape and Portrait */
@media (max-width: 767px) {
    .ErrorBoundary .ErrorBoundaryContent .Buttons {
        flex-direction: column;
    }

    .ErrorBoundary .ErrorBoundaryContent .Buttons .Button {
        margin-right: 0;
        margin-bottom: 10px;
    }
}