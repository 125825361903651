.LoyaltyCollection {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 72px;
  width: 100%;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.LoyaltyCollection .SectionDescription {
  margin: 12px;
  text-align: center;
}

@media(max-width: 767px) {
  .LoyaltyCollection {
    padding: 32px 8px;
  }
}
