.OrderCard {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
}

.OrderCard,
.OrderCard > .panel-heading {
  background-color: var(--color-content);
  color: var(--color-text-primary);
}

.OrderCard > .panel-heading {
  position: relative;
  padding: 10px;
}

.OrderCard .OrderNumber {
  color: var(--color-secondary);
}

.OrderCard .OrderCreated {
  display: flex;
  align-items: center;
}

.OrderCard .OrderBody {
  margin-top: 4px;
}

.OrderCard .OrderBody .GglocationIcon {
  margin-top: 3px;
  height: 15px;
  width: auto;
  margin-right: 5px;
  vertical-align: middle;
}

.OrderCard .OrderPickupInformation {
  display: flex;
  flex-direction: column;
}

.OrderCard .OrderOtherInformation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.OrderCard .OrderGglocationInfo {
  display: flex;
  flex: 1;
  margin-bottom: 8px;
}

.OrderCard .GglocationName {
  display: inline-flex;
}

.OrderCard .GglocationName,
.OrderCard .DeliveryAddress {
  font-weight: bold;
}

.OrderCard .GglocationName li {
  color: var(--color-text-primary);
  background-color: transparent;
  position: relative;
  padding: 0;
}

.OrderCard .GglocationName li img {
  display: none;
}

.OrderCard .OrderStatusDescription {
  font-size: 0.9em;
}

.OrderCard .OrderStatusDescription time {
  font-weight: bold;
}

.OrderCard .OrderStatusLabel {
  padding: 0px 10px;
  font-size: 0.8em;
  color: var(--color-text-contrast);
  margin-right: -10px;
}

.OrderCard .OrderStatusLabel.Pending {
  background-color: #92D27F;
}

.OrderCard .OrderStatusLabel.Cancelled {
  background-color: #8CA1A5;
}

.OrderCard .OrderStatusLabel.Completed {
  background-color: #92C57A;
}

.OrderCard .OrderStatusLabel.Ready {
  background-color: var(--color-primary);
}

.OrderCard .OrderPrice {
  display: flex;
  align-items: center;
}

.OrderCard .OrderPrice .CurrencySymbol {
  font-size: 11px;
}

.OrderCard .OpenIndicator {
  margin-left: 8px;
}

.OrderCard .Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px -15px -15px -15px;
  padding: 15px;
}

.OrderCard .Footer,
.OrderCard.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: solid 1px #ddd;
}

.OrderCard .Footer .Button.blue-link {
  font-size: 0.9em;
}

.OrderCard .Footer .Button.btn-primary {
  margin: -10px;
  padding: 8px 24px;
  float: right;
  border-radius: 3px;
  box-shadow: none;
  font-size: 0.9em;
}

.OrderCard .panel-body {
  padding-top: 4px;
}
