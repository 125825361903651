.MenuDetailsAddHeader {
  padding: 0px 32px 16px 32px;
  border-bottom: solid 1px #FFFFFF;
  display: flex;
}

.MenuDetailsAddHeader h4 {
  display: inline-block;
}

.MenuDetailsAddHeader .CategoryChoice {
  flex: 1;
}

.MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
  margin: 6px 0;
  text-align: right;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .MenuDetailsAddHeader {
    padding: 0;
    border-bottom: none;
  }

  .MenuDetailsAddHeader h4 {
    display: none;
  }

  .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
    margin: 0;
    text-align: center;
  }
}
