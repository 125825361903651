.IngredientChoiceWrapper.disabled {
  opacity: 0.5 !important;
}

.IngredientChoiceWrapper.IngredientBig {
  width: 150px;
}

.IngredientChoiceWrapper.IngredientMedium {
  width: 134px;
}

.IngredientChoiceWrapper.IngredientSmall {
  width: 22%;
}

.IngredientChoiceWrapper.IngredientExtraSmall {
  width: 110px;
}

.IngredientChoiceWrapper.IngredientMinimal {
  width: 90px;
}

.IngredientChoiceWrapper.IngredientMinimal .IconInfo {
  display: none;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName {
  padding: 0 5px;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName p {
  display: none;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName h5 {
  font-size: 0.85em !important;
  text-align: center;
  line-height: 17px;
  margin: 5px 0 0;
  width: 100%;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoice .IngredientPicture img {
  object-fit: initial;
  position: initial;
  top: initial;
  transform: initial;
}

.IngredientChoiceWrapper.IngredientMinimal .Status {
  top: 15%;
}

.IngredientChoiceWrapper.IngredientMinimal .Status .lnr {
  font-size: 3em;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoice .Price {
  display: none;
}

.IngredientChoiceWrapper {
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
}

.IngredientChoice {
  height: 100%;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3);
  background-color: #FFFFFF;
  position: relative;
  padding: 0 0 10px 0;
  flex: 1;
}

.IngredientMedium .IngredientDetails {
  display: flex;
  flex-direction: column;
}

.IngredientSmall .IngredientDetails > li {
  display:flex;
}

.IngredientMedium .IngredientDetails> li {
  display: flex;
  align-items: center;
}

.IngredientDetails > li:first-child,
.IngredientDetails > li:nth-child(2) {
  position: relative;
}

.IngredientMedium .IngredientDetails> li:nth-child(2) {
  flex: 1 1 auto;
}

.IngredientMedium .IngredientDetails {
  height: 100%;
}

.IngredientChoice:hover {
  cursor: pointer;
}

.IngredientChoice.selected::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid var(--color-primary);
  z-index: 9888;
}

.IngredientChoice.selected {
  background-color: rgba(47,121,58,.4) !important;
  border: none !important;
}

.IngredientChoice.unavailable .IngredientPicture::before {
  content: "Unavailable";
  font-family: 'Gill Sans Bold';
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255,255,255,0.8);
}

.IngredientChoice.unavailable .IngredientChoiceName::before {
  content:"";
  font-size: 20px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255,255,255,0.35);
}

.IngredientChoice .Status {
  display: none;
}

.IngredientChoice.selected .Status {
  display: block !important;
  color: var(--color-text-contrast);
  position: absolute;
  z-index: 2321;
  width: 100%;
  text-align: center;
  text-shadow: 0 2px 3px rgba(0,0,0,0.3);
  font-size: 1.1em;
}

.IngredientBig .IngredientChoice.selected .Status {
  left: 0;
  top: 20%;
}

.IngredientBig .IngredientChoice.selected .Status .lnr {
  font-size: 2.4em;
}

.IngredientBig .IngredientDetails li:nth-child(2) {
  display: flex;
  padding-right: 8px;
}

.IngredientMedium .IngredientChoice.selected .Status {
  left: 0;
  top: 20%;
}

.IngredientMedium .IngredientChoice.selected .Status .lnr {
  font-size: 2.4em;
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .Status {
  left: initial;
  top: initial;
  padding: 8px 0 0 0;
}

.IngredientSmall .IngredientChoice.selected .Status {
  left: 0;
  top: 20%;
}

.IngredientSmall .IngredientChoice.selected .Status .lnr {
  font-size: 1.8em;
  padding-right: 4px;
  vertical-align: middle;
}

.IngredientSmall .IngredientChoice.selected .Status .line-break {
  display: none;
}

.IngredientExtraSmall .IngredientChoice.selected .Status {
  padding: 20% 0 0 0;
}

.IngredientExtraSmall .IngredientChoice.selected .Status .lnr {
  font-size: 2.4em;
}

.IngredientChoice .IngredientPicture {
  width: 100%;
  overflow: hidden;
  background-color: #FFFFFF;
}

.IngredientBig .IngredientPicture {
  height: 150px;
}

.IngredientMedium .IngredientPicture {
  height: 130px;
}

.IngredientSmall .IngredientChoice .IngredientPicture {
  height: 86px;
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientPicture {
  height: 50px;
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientDetails {
  padding-bottom: 36px;
}

.IngredientChoiceWrapper.IngredientExtraSmall .IngredientPicture {
  width: 100%;
  height: 96px;
  overflow: hidden;
}

.CounterWrapper {
  display: none;
}

.IngredientChoice.selected .CounterWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background-color: #FFFFFF;
  border-right: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  border-left: 2px solid var(--color-primary);
  z-index: 9889;
}

.IngredientChoiceWrapper .IngredientChoice.selected .IngredientPicture {
  position: relative;
}
.IngredientChoiceWrapper .IngredientChoice.selected .IngredientPicture:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background-color: rgba(47,121,58,0.40);
}

.IngredientChoice .IngredientPicture img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.IngredientChoiceName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 8px 0 8px;
  width: 100%;
  position: relative;
}

.IngredientChoiceName h5 {
  line-height: 1.1em;
  width: 83%;
}

.IngredientChoiceName p {
  color: #95989A;
  font-size: 0.8em;
  width: 83%;
}

.IconInfo {
  color: #D3C0B0 !important;
  font-size: 1.5em;
  font-style: italic;
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 10px;
  -top: -3px;
  z-index: 9889;
}

.IconInfo:hover {
  color: #B29C8A;
}

/*--- Large Desktop Only---*/
@media (min-width: 1800px) {
  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .Status {
    padding-top: 14%;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .IngredientSmall .IngredientChoice.unavailable::before {
    justify-content: flex-start;
    padding-bottom: 0px;
  }

  .IngredientChoiceWrapper.IngredientSmall {
    width: 100%;
    max-width: none;
    padding-right: 0;
    padding-bottom: 8px;
  }

  .IngredientChoiceWrapper.IngredientMedium {
    width: 164px;
  }

  .IngredientMedium .IngredientChoice .IngredientPicture {
    height: 150px;
  }

  .IngredientMedium .IngredientDetails> li {
    display: flex;
    align-items: center;
  }

  .IngredientMedium .IngredientDetails> li:nth-child(2) {
    padding-right: 8px;
  }

  .IngredientChoiceWrapper.IngredientSmall .IngredientChoice {
    padding-bottom: 0;
  }

  .IngredientSmall .IngredientChoiceMultiple.IngredientChoice.selected .Status {
    width: 98px;
  }

  .IngredientSmall .IngredientChoice .IngredientDetails {
    display: flex;
    padding-right: 10px;
  }

  .IngredientSmall .IngredientDetails > li {
    display:flex;
    align-items: center;
  }

  /* Element that contains the picture */
  .IngredientSmall .IngredientDetails > li:nth-child(1) {
    width: 98px;
  }

  /* Element that contains details*/
  .IngredientSmall .IngredientDetails > li:nth-child(2) {
    flex: 1 1 auto;
  }

  .IngredientSmall .IngredientPicture {
    width: 98px;
    background-color: #FFFFFF;
  }

  .IngredientSmall .IngredientChoice.selected .Status {
    width: 98px;
  }

  .IngredientSmall .IngredientChoice.selected .Status .lnr {
    padding-right: 0;
  }

  .IngredientSmall .IngredientChoice.selected .Status .line-break {
    display: block;
    height: 8px;
  }

  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientDetails {
    min-height: 130px;
    padding-bottom: 42px;
  }

  .IngredientSmall .IngredientChoiceName {
    padding: 10px 0 8px 5px;
  }

  .IngredientChoiceWrapper.IngredientMinimal {
    width: 30%;
    max-width: 115px;
  }

  .IngredientChoice.selected .CounterWrapper {
    padding: 7px 0;
  }

  .IconInfo {
    flex: 1 0 auto;
    height: 44px;
    width: 44px;
    position: unset;
    border: 1px solid #D3C0B0;
    border-radius: 21px;
    color: #D3C0B0;
    z-index: 9889;
  }

  .IconInfo:hover {
    color: #B29C8A;
    background-color: rgba(211,192,176,0.3);
  }
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: 130px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(4n) {
    padding-right: 0;
  }

  .IngredientExtraSmall .IngredientPicture img {
    height: 130px;
  }

  .IngredientExtraSmall .IngredientChoiceName h5 {
    -bottom: 6px;
  }

  .IngredientExtraSmall.s .Status span {
    font-size: 3.8em;
    padding: 0 !important;
 }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .IngredientChoiceWrapper.IngredientBig,
  .IngredientChoiceWrapper.IngredientMedium {
    width: calc((100% / 3) - (14px * 2 / 3));
    position: relative;
    padding-bottom: 10px;
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientBig:nth-child(3n),
  .IngredientChoiceWrapper.IngredientMedium:nth-child(3n) {
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: calc((100% / 4) - (14px * 3 / 4));
    position: relative;
    padding-bottom: 10px;
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(4n) {
    padding-right: 0;
  }

  .IngredientExtraSmall .IngredientChoice.selected .Status {
    padding: 15% 0 0 0;
  }

  .IngredientBig .IngredientPicture {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .IngredientMedium .IngredientPicture {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .IngredientExtraSmall .IngredientPicture img {
    width: 100%;
    height: calc((100vw - 42px)/4);
    overflow: hidden;
    background-color: #FFFFFF;
  }

  .IngredientExtraSmall .IngredientChoiceName h5 {
    margin-bottom: 0;
  }

  .IngredientChoice {
    padding-bottom: 10px;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .IngredientChoiceWrapper.IngredientBig,
  .IngredientChoiceWrapper.IngredientMedium {
    width: calc(100% / 2 - 7px);
    position: relative;
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientBig:nth-child(odd),
  .IngredientChoiceWrapper.IngredientMedium:nth-child(odd) {
    width: calc(100% / 2 - 7px);
    padding-right: 14px;
  }

  .IngredientBig .IngredientChoice .IngredientPicture,
  .IngredientMedium .IngredientChoice .IngredientPicture {
    width: 100%;
    height: calc((100vw - 54px) / 2);
    overflow: hidden;
  }

  .IngredientChoice.selected .Status .lnr {
    font-size: 3.5em;
  }

  .IngredientExtraSmall .IngredientChoice.selected .Status {
    padding: 35% 0 0 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: calc(100% / 2 - 7px);
    position: relative;
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(odd){
    width: calc(100% / 2 - 7px);
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall .IngredientPicture {
    width: 100%;
    height: calc((100vw - 54px) / 2);
    overflow: hidden;
  }

  .IngredientChoiceWrapper.IngredientMinimal {
    width: calc(100% / 4 - 10px);
    padding-right: 10px;
    padding-bottom: 10px;
  }
  
  .IngredientChoiceWrapper.IngredientMinimal .Status .lnr {
    font-size: 2.5em;
  }
}
