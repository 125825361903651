.GglocationName li {
  color: white;
  background-color: var(--color-primary);
  padding: 8px 15px;
  display: inline-block;
  z-index: 998;
  position: absolute;
}
.GglocationName li .Name {
  vertical-align: middle;
}
