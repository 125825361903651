.TopBar {
  width: 100%;
  height: 56px;
  background-color: var(--color-primary);
  text-align: center;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  flex: 0 0 auto;
  display: flex;
  padding: 0 20px;
  color: var(--color-text-contrast);
}

.TopBar .TopBarTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TopBar .TopBarTitle p {
  font-family: 'Gill Sans Bold';
}

.TopBar .TopBarTitle p svg,
.TopBar .TopBarTitle p img {
  vertical-align: middle;
  margin: 0 4px 4px 0;
}

.TopBar .TopBarLeftButton,
.TopBar .TopBarRightButton {
  flex: 1 1 0px;
  display: flex;
  align-items: center;
}

.TopBar .TopBarLeftButton {
  justify-content: flex-start;
}

.TopBar .TopBarRightButton {
  justify-content: flex-end;
}

.TopBar .lnr {
  font-size: 1.5em;
}

.TopBar .Button > span {
  display: flex;
  align-items: center;
}

.TopBar .TopBarLeftButton .BackButtonDesktop span {
  padding-right: 4px;
}

.TopBar .TopBarLeftButton .BackButtonDesktop span img {
  width: 10px;
  height: auto;
}

ul.topbar-info li {
  padding: 0 5px;
  display: inline-block;
  color: var(--color-text-contrast);
}
.TopBar .TopBarSubtitle {
  font-style: italic;
}
ul.topbar-info li img {
  width: 26px;
  height: auto;
  height: auto;
  margin: -6px 2px 0 2px;
}
.TopBar .logo-header img {
  height: 34px;
  width: auto;
}
.TopBar .btn-right {
  color: var(--color-text-contrast);
  position: absolute;
  padding-top: 20px;
  top: 0px;
  right: 10px;
}
.TopBar .btn-right .btn-list {
  float: right;
  padding-left: 5px;
}
.TopBar .btn-right button.btn-text {
  font-size: 0.9em;
}

.TopBar p {
  color: var(--color-text-contrast);
  padding-top: 18px;
  font-size: 1.05em;
}
