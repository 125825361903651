.Register .RecaptchaContainer div {
  width: 100% !important;
  margin-top: 10px;
}

.Register .Checkbox {
  margin-bottom: 22px;
}

.Register .Checkbox label {
  font-weight: normal;
  color: #3c6042;
  text-align: left;
}

.Register .Checkbox label a {
  color: var(--color-text-hyperlink);
  text-decoration: underline;
}

.Register .Checkbox input {
  margin-bottom: unset;
}

/* Checkmark */
.Register .Checkbox label::after {
  color: transparent;
}

/* Checkmark on the checked state*/
.Register .Checkbox input[type='checkbox']:checked + label::after {
  color: var(--color-text-contrast);
}

/* Checkbox on checked state */
.Register .Checkbox input[type='checkbox']:checked + label::before {
  background-color: var(--color-secondary);
}
