.OrderItemPrice {
  display: inline-block;
  padding-right: 2px;
}

.OrderItemPrice.isDiscounted .OriginalPrice {
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 0.9em;
  color: #81A788;
}
