.Placeholder {
    text-align: center;
    margin: 20px;
    color: #3B6142;
}

.Placeholder .lnr {
    font-size: 1.8em;
    display: block;
    margin-bottom: 15px;
}