.CartButton {
  display: flex;
  position: relative;
}

.CartButton .Quantity {
  background-color: #92D27F;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #FFFFFF;
  position: absolute;
  top: -8px;
  right: -8px;
}
