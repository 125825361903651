.Checkbox label {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.Checkbox label::before {
  content: "";
  min-width: 27px;
  min-height: 27px;
  border-radius: 4px;
  border: 1px solid var(--color-secondary) ;
  fill: transparent;
  margin-right: 10px;
}

/* Checkmark */
.Checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
  height: 9px;
  width: 15px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  margin: -1.5px auto 10px 6px;
  color:var(--color-secondary);
  opacity: 0.2;
}

/*Hide the checkmark by default*/
.Checkbox input[type="checkbox"] + label::after {
  content: "";
}
/*Unhide the checkmark on the checked state*/
.Checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  opacity: 1;
}

.Checkbox input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
