ul.MenuCategory {
  margin-top: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: center;
  width: 100%;
}

.category-desc {
  margin-bottom: 20px;
}

.category-desc p {
  font-size: 0.95em;
  width: 70%;
  line-height: 1.6em;
  margin: 0 auto;
}

/*--- Mobile Landscape & Portrait---*/
@media (max-width: 767px) {
  .category-desc {
    display: none;
  }
  ul.MenuCategory.scrollable {
    flex-wrap: nowrap;
    width: 98vw;
    margin: 0 auto;
    overflow-x: scroll;
    flex-direction: row;
    justify-content: initial;
  }
  ul.MenuCategory.scrollable::-webkit-scrollbar {
    display: none;
  }
  ul.MenuCategory.scrollable .MenuItem {
    flex-shrink: 0;
  }
}
