.ScannerModal .modal-dialog {
  display: flex;
  flex-direction: column;
}

.ScannerModal .modal-content {
  height: 100%;
}

@media (max-width: 767px) {
  .ScannerModal {
    flex-direction: column;
  }
  .ScannerModal .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
  }

  .ScannerModal .modal-dialog {
    width: 100%;
    max-width: none;
    display: flex;
    flex: 1;
  }

  .ScannerModal .Topbar {
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    background-color: var(--color-primary);
    flex: 1;
    color: var(--color-text-contrast);
    padding: 0 16px;
  }

  .ScannerModal .Topbar .Title {
    flex: 1;
  }

  .ScannerModal .Topbar .TopbarButton {
    font-size: 1.5em;
  }

  .ScannerModal .BarcodeScannerContainer {
    position: relative;
    flex: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ScannerModal .fa {
    font-size: 6em;
    height: fit-content;
  }

  .ScannerModal .ScanInstructions {
    color: var(--color-text-contrast);
    padding: 25px;
    height: 84px;
  }

  .ScannerModal .modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    padding: 0;
    background-color: black;
  }

  .ScannerModal .BarcodeScannerComponent {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .Rectangle {
    position: absolute;
    width: 300px;
    height: 300px;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid #00ff3b;
    background-color: 'transparent';
  }
}

.ScannerModal .DesktopNote {
  margin: 16px;
}
