/* .ItemSeparator */
.ItemSeparator ul li {
  display: inline-block;
}

.ItemSeparator .line {
  border-top: 1px solid rgba(60, 97, 66, 0.4);
  margin-top: 20px;
  height: 2px;
  width: 100%;
}

.ItemSeparator .line.noLine {
  border-top: none;
}

.ItemSeparator {
  position: relative;
  text-align: center;
}

.ItemSeparator .ItemTitle {
  background-color: #FFFFFF;
  position: absolute;
  top: 12px;
  left: 24px;
  padding: 0 5px;
}

/*--- Tablet and Mobile Only ---*/
@media (min-width: 480px) and (max-width: 992px) {
  .ItemSeparator {
    margin-bottom: -22px;
  }

  .ItemSeparator .ItemTitle {
    position: relative;
    top: -18px;
    left: 0;
  }
}
