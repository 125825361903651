.ErrorText {
  color: var(--color-text-error);
}

.EditPartnerEmail.panel ul li.form-editable {
  padding: 2px 0 0 0;
}

.EditPartnerEmail .PartnerEmailLabel {
  display: flex;
  justify-content: space-between;
}

.EditPartnerEmail .PartnerEmailLabel > h6 {
  padding-top: 4px;
}

.EditPartnerEmail .Badge {
  margin-right: 16px;
}

.EditPartnerEmail p.notice {
  margin-top: 8px;
}

.EditPartnerEmail .PartnerInputExtraInfo {
  display: flex;
}
