.InputAlertText {
  color: var(--color-text-error);
  margin-top: -10px;
  margin-bottom: 20px;
}

.InputAlertText.lower {
  color: var(--color-text-error);
  margin-top: 0;
  margin-bottom: 10px;
}
