/* .shop-info.content-main */
.GglocationInfo p,
.GglocationInfo a {
  font-size: 1.125em;
}

.GglocationInfo .AddressSticker {
  border-radius: 4px;
  background-color: var(--color-content);
  border: 1px solid #D0D0D0;
  padding: 17px 20px 15px 15px;
  font-size: 14px;
  color: var(--color-text-primary);
  text-align: left;
}

.GglocationInfo .AddressSticker .AddressNameLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.GglocationInfo .AddressNameLine .Distance {
  font-size: 1.125em;
}

.GglocationInfo .AddressTitle {
  display: flex;
  align-items: center;
}

.GglocationInfo .gglocationAddress {
  margin-bottom: 8px;
}

.GglocationInfo .StoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.GglocationInfo .StoreInfo a {
  width: auto;
  margin-left: auto;
}
