.MenuDetails {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}

.MenuDetails.Add-3 .MenuDetailImage, .MenuDetails.Add-4 .MenuDetailImage {
  display: none;
}
.MenuDetailsContentWrapper {
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
}

@media(min-width:993px) {
  .MenuDetails .MenuDetailsContentWrapper > img, 
  .MenuDetails .CustomizeRight .NutritionalBlock {
    display: none;
  }
}

@media(max-width:992px) {
  .MenuDetails {
    flex-direction: column;
  }

  .MenuDetails .MenuDetailsContentWrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .MenuDetails .MenuDetailsContentWrapper .MenuContentFrame {
    overflow: visible;
    padding-bottom: 64px;
  }
  .MenuDetails .MenuDetailsContentWrapper > img{
    object-fit: cover;
    max-height: 300px;
    width: 100%;
  }

  .MenuDetails .CustomizeLeft {
    display: none;
  }

  .NotPremadeItem {
    max-height: 100%;
  }
}
