.AddressCard .AddressElements {
  display: flex;
  border-radius: 5px;
  background-color: var(--color-panel-primary) !important;
  padding: 18px;
  font-size: 14px;
  width: 600px;
  color: var(--color-text-primary);
  margin-bottom: 8px;
  border: none !important;
}

.AddressCard .AddressElements li {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.AddressCard .AddressElements .lnr-trash {
  font-size: 1.3em;
}

.AddressCard.selectable .AddressElements {
  display: flex;
  border-radius: 4px;
  border: 1px solid #C1B9B1 !important;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6 !important;
  padding: 15px 15px 15px 20px;
  text-decoration: none;
  color: black;
  margin-bottom: 8px;
  width: unset;
}

.AddressElements .select {
  height: 100%;
}

.AddressCard.selectable .AddressElements.selected {
  border: 2px solid var(--color-secondary) !important;
}

.AddressCard.selectable .AddressElements .AddressText {
  color: #B1A59A;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.AddressCard .AddressElements .AddressText .addressName,
.AddressCard .AddressElements .AddressText .address.hasAddressName,
.AddressCard.selectable .AddressElements .AddressText .addressName,
.AddressCard.selectable .AddressElements .AddressText .address.hasAddressName {
  font-size: 16px;
  font-weight: 600;
}
.AddressCard .AddressText .address {
  margin-bottom: 0;
}

.AddressCard.selectable .AddressElements.selected .AddressText .addressName,
.AddressCard.selectable .AddressElements.selected .AddressText .address.hasAddressName {
  color: var(--color-text-primary);
}

.AddressCard.selectable .AddressElements .AddressText .address {
  font-size: 11px;
}

.AddressCard.selectable .AddressElements.selected .AddressText .address {
  color: #7D7769;
}

.AddressCard.selectable .AddressElements .Button {
  height: 60px;
  width: unset !important;
  padding: 12px 30px;
  background-color: #C1B9B1;
  border-color: #C1B9B1;
}

.AddressCard.selectable .AddressElements .Button:hover {
  background-color: #AD9E8F !important;
  border-color: #AD9E8F !important;
}

.AddressCard.selectable .AddressElements.selected .Button {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.AddressCard.selectable .AddressElements > li {
  margin: 2px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 992px) {
  .AddressCard .AddressElements {
    width: unset;
  }

  .AddressCard.selectable .AddressElements .Button {
    padding: 12px 20px;
  }
}
