.PastOrders {
  max-width: 658px;
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.PastOrders .Title {
  color: var(--color-text-primary);
  margin-bottom: 12px;
}

.PastOrders .tabsRow {
  display: flex;
  width: 70%;
  border-bottom: 3px solid var(--color-primary);
  margin-bottom: 16px;
}

.PastOrders .tabsRow .Button:first-child {
  margin-right: 10px;
}

.PastOrders .tabsRow .Button {
  font-size: 1em;
  font-weight: bold;
  padding: 8px 20px;
  flex: 1;
  background-color: var(--color-content);
  color: var(--color-primary);
  border: 1px solid #d6d6d6;
  border-radius: 10px 10px 0 0;
  clip-path: inset(-5px -5px 0px -5px);
}

.PastOrders .tabsRow .Button.selected,
.PastOrders .tabsRow .Button.selected:focus {
  background-color: var(--color-primary) !important;
  padding: 10px;
  margin-top: 0px;
  border: none !important;
  color: var(--color-text-contrast);
}

.PastOrders .tabsRow .Button.selected > span {
  padding-top: 10px;
}

.PastOrders .PastOrderListWrapper,
.PastOrders .PastScansWrapper {
  overflow-y: auto;
}

@media (max-width: 767px) {
  .PastOrders .tabsRow {
    width: 100%;
  }
}
